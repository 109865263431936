<template>
  <div class="content bg-light">
    <div class="row p-sm-5 pt-3">
      <div class="col">
        <div class="alert alert-info fs-3" role="alert" v-if="newSession">
          <span class="welcom-text"></span>
        </div>
        <div class="alert alert-info fs-3" role="alert" v-else>
          Bienvenue sur la plateforme de candidature aux activités d'UniPod Bénin!
        </div>
        <div class="alert alert-success fs-3 text-center" role="alert">
          Les candidatures sont ouvertes.
        </div>
        <div class="col mt-5 mb-4">
          <h4 class="section-title text-uppercase">SECTION INFO</h4>
        </div>
        <div
          class="card border-info mb-3"
          v-for="info in informations"
          :key="info.id"
        >
          <div class="card-body ql-editor">
            <p class="card-text" v-html="info.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Typed from "typed.js";

export default {
  name: "Home",
  mounted() {
    if (localStorage.getItem("candidature_step")) {
      this.preselected = localStorage.getItem("candidature_step") == 4;
      this.selected = localStorage.getItem("candidature_step") == 5;
    }

    this.getInformations();
    this.getUser();
    document.querySelector("body").style.backgroundColor = "#f8f9fa";
  },
  data() {
    return {
      informations: {},
      newSession: this.$store.state.newSession,
    };
  },
  methods: {
    getInformations() {
      this.$http.get("announcements").then((result) => {
        this.informations = result.data;
      });
    },
    getUser() {
      this.$http.get("users/000").then((result) => {
        localStorage.setItem("candidature_step", result.data.step);

        if (this.newSession) {
          this.$store.commit("updateSession");
          var options = {
            strings: [
              `Bonjour ${result.data.first_name}.`,
              "Bienvenue sur la plateforme de candidature aux activités d'Unipod Bénin!",
            ],
            typeSpeed: 40,
            startDelay: 1005,
            backDelay: 1005,
            backSpeed: 40,
          };

          new Typed(".welcom-text", options);

          sessionStorage.setItem("new_session", "false");
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../assets/styles/quill.core.css";
.container {
  background-color: #80808096;
  /* min-height: 50vh; */
}

.content {
  padding-left: 20px; 
  padding-right: 20px;
}

.section-title {
  border-bottom: 3px solid #3490dc;
}

@media (min-width: 992px) {
.content {
  padding-left: 150px; 
  padding-right: 150px;
}
}
</style>
