<template>
  <div class="event-card">
    <router-link style="text-decoration: none;" :to="`/evenement/${event.id}/${event.slug}`">
      <img :src="'https://admin.benin-unipod.bj/api/candidate/storage/' + event.image" :alt="event.title"
        class="event-image" />
      <!-- <img :src="require(`../../../eeia-api/storage/app/public/${event.image}`)" :alt="event.title" class="event-image"> -->
    </router-link>
    <div class="event-content">
      <div class="event-details">
        <router-link style="text-decoration: none; color: inherit;" :to="`/evenement/${event.id}/${event.slug}`">
          <h4 class="event-title">{{ truncateText(event.title, event.extract, 290).title }}</h4>
        </router-link>
        <p class="event-date">{{ formatDate(event.start_date, event.end_date) }}</p>
        <p class="event-description">{{ truncateText(event.title, event.extract, 290).extract }}</p>
      </div>
      <div class="event-buttons">
        <router-link class="text-uppercase" style="text-decoration: none;"
          :to="`/evenement/${event.id}/${event.slug}`">Lire plus<i class="fa fa-chevron-right"></i></router-link>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(startDate, endDate) {
      const formattedStartDate = new Date(startDate).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
      const formattedEndDate = new Date(endDate).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });

      if (formattedStartDate === formattedEndDate) {
        return formattedStartDate;
      } else {
        return `${formattedStartDate} au ${formattedEndDate}`;
      }
    },

    truncateText(title, extract, maxLength) {
      const combinedLength = title.length + extract.length;
      if (combinedLength > maxLength) {
        return { title, extract: extract.slice(0, maxLength - title.length) + '...' };
      } else {
        return { title, extract };
      }
    }
  },
};
</script>
  
<style scoped>
.event-card {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  margin-top: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-image {
  width: 100%;
  height: 300px;
  object-fit: fill;
}

.event-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.event-details {
  flex: 1;
  padding: 20px;
}

.event-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.event-description {
  margin-bottom: 10px;
}

.event-date {
  font-style: italic;
  color: #888;
}

.event-card {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-color: #eef9ffe8;
}

.event-card:hover .text-uppercase i {
  transform: translateX(25px);
}

.text-uppercase i {
  transition: transform 0.3s ease-in-out;
  margin-left: 10px;
}

.event-buttons {
  padding: 0 20px 20px;
  margin-top: auto;
}

.text-uppercase {
  margin-top: 10px;
}

.event-buttons .btn {
  padding: 10px;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.btn-right {
  background-color: #d53343;
  color: white;
}

@media (max-width: 576px) {
  .event-buttons {
    margin-top: 10px;
  }

  .text-uppercase {
    display: block;
    text-align: center;
  }
}
</style>
  