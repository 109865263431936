<template>
  <div class="card font" style="margin-top: 30px; margin-bottom: 30px;">

    <div v-if="project.niveau_academique === 'non_universitaire'" class="justify-text px-3 py-2">
      <p><strong>Objectifs du projet: </strong>{{ truncateText(project.objectifs_projet,
        200).description }}</p>
      <p><strong>Description du projet: </strong>{{ truncateText(project.description_projet,
        820).description }}</p>
      <div class="text-end">
        <button class="btn btn-primary" @click="followProject2">Suivre mon projet</button>
      </div>
    </div>

    <div v-else class="row g-0">
      <div class="col-md-4">
        <img class="img-fluid"
          :src="projectImage ? `https://admin.benin-unipod.bj/api/candidate/storage/${projectImage}` : require('../static/default-picture.png')"
          alt="Unipod Projet">
        <!-- <img class="img-fluid" :src="require(`../../../eeia-api/storage/app/public/projects/${projectImage}`)" alt="Unipod Projet"> -->
      </div>
      <div class="col-md-8 justify-text">
        <div class="card-body">
          <h5 class="card-title"><strong>Libellé : </strong>{{ truncateText(project.titre_memoire,
            250).description }}</h5>
          <h6 class="card-subtitle mt-3 mb-3 text-muted"><strong>Intitulé du projet: </strong>{{ project.titre_solution }}
          </h6>
          <p class="card-text"><strong>Description: </strong>{{ truncateText(project.description_solution,
            200).description }}</p>
          <div class="text-end">
            <button class="btn btn-primary" @click="followProject1">Suivre mon projet</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    projectImage() {
      if (this.project.images) {
        try {
          const imagesArray = JSON.parse(this.project.images);
          if (Array.isArray(imagesArray) && imagesArray.length > 0) {
            return imagesArray[0];
          }
        } catch (error) {
          console.error('Erreur lors de l\'analyse des images :', error);
        }
      }
      return null;
    }
  },
  methods: {
    truncateText(description, maxLength) {
      if (description.length > maxLength) {
        return { description: description.slice(0, maxLength) + '...' };
      } else {
        return { description };
      }
    },

    followProject1() {
      const Title = this.truncateText(this.project.titre_solution, 100).description;
      this.$router.push({ name: 'StatusProject', params: { id: this.project.id, title: Title } });
    },

    followProject2() {
      const Title = this.truncateText(this.project.objectifs_projet, 100).description;
      this.$router.push({ name: 'StatusProject', params: { id: this.project.id, title: Title } });
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  min-height: 100px;
}

.card-title {
  font-size: large;
}

.card img {
  height: 100%;
  /* object-fit: cover;  */
}

.justify-text {
  text-align: left;
}

.font {
  font-family: 'proxima-nova', sans-serif;
}
</style>