<template>
  <div class="py-16" style="margin-top: 25px;">
    <div class="row justify-content-center">
      <div class="event-card" v-for="news in cardNews" :key="news.id">
        <router-link style="text-decoration: none;" :to="getArticleLink(news.id, news.slug, getCategoryName(news.category_id))">
          <img class="event-image" :src="'https://admin.benin-unipod.bj/api/candidate/storage/' + news.image"
              :alt="news.title">
          <!-- <img class="event-image" :src="require(`../../../eeia-api/storage/app/public/${news.image}`)" :alt="news.title"> -->
        </router-link>
        <div class="category-badge" :class="getCategoryClass(news.category_id)">
          {{ getCategoryName(news.category_id) }}
        </div>
        <div class="event-details">
          <div class="event-content">
            <router-link style="text-decoration: none; color: inherit;" :to="getArticleLink(news.id, news.slug, getCategoryName(news.category_id))">
              <h4 class="event-title">{{ truncateText(news.title, news.extract, 200).title }}</h4>
            </router-link>
            <p class="event-date">{{ getRelativeDate(news.start_date, news.end_date) }}</p>
            <p class="event-description">{{ truncateText(news.title, news.extract, 200).extract }}</p>
          </div>
          <div class="event-buttons">
            <router-link class="text-uppercase" style="text-decoration: none;" :to="getArticleLink(news.id, news.slug, getCategoryName(news.category_id))">
              Lire plus<i class="fa fa-chevron-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardNews: [],
      categories: [],
    };
  },
  methods: {
    fetchAllBlogsEventsData() {
      // Utilisez ici l'appel à votre API Laravel pour récupérer les catégories
      // Par exemple, vous pouvez utiliser la bibliothèque axios pour effectuer une requête GET
      this.$http.get('allblogsevents')
        .then(response => {
          this.cardNews = response.data;
        })
        .catch(error => {
          console.error(error);
        });

      this.$http.get('categories')
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getCategoryName(categoryId) {
      // Rechercher le nom de la catégorie correspondant à l'ID de la catégorie
      const category = this.categories.find(cat => cat.id === categoryId);
      return category ? category.name : '';
    },
    getCategoryClass(categoryId) {
      const categoryName = this.getCategoryName(categoryId);
      return categoryName === 'Evènements' ? 'bg-yellow' : 'bg-primary';
    },
    getArticleLink(id, slug, category) {
      // Construire le lien vers la page des détails de l'article avec les paramètres d'ID et de slug
      if (category === 'Evènements') {
        return `/evenement/${id}/${slug}`;
      } else {
        return `/blog/${id}/${slug}`;
      }
    },
    formatDate(date) {
        return new Date(date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
    },
    getRelativeDate(date) {
        const now = new Date();
        const postDate = new Date(date);
        const diffTime = Math.abs(now - postDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
        if (diffDays === 0) return "Aujourd'hui";
        if (diffDays === 1) return "Hier";
        if (diffDays < 7) return `Il y a ${diffDays} jours`;
        if (diffDays < 30) return `Il y a ${Math.floor(diffDays / 7)} semaines`;
        if (diffDays < 365) return `Il y a ${Math.floor(diffDays / 30)} mois`;
        return `Il y a ${Math.floor(diffDays / 365)} ans`;
      },
    truncateText(title, extract, maxLength) {
      const combinedLength = title.length + extract.length;
      if (combinedLength > maxLength) {
        return { title, extract: extract.slice(0, maxLength - title.length) + '...' };
      } else {
        return { title, extract };
      }
    }

  },
  mounted() {
    this.fetchAllBlogsEventsData();
  }
};
</script>
<style scoped>
.event-card {
  display: flex;
  flex-direction: column;
  height: 555px;
  position: relative;
  /* width: 100%; */
  max-width: 450px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #eef9ffe8;
  padding: 0;
}

.event-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  /* display: block; */
}

.category-badge {
  position: absolute;
  top: 10px;
  /* left: 10px; */
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.event-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  justify-content: space-between;
} 

.event-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.event-description {
  margin-bottom: 10px;
}

.event-card {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.event-card:hover .text-uppercase i {
  transform: translateX(25px);
}

.text-uppercase i {
  transition: transform 0.3s ease-in-out;
  margin-left: 10px;
}

.event-content {
  flex-grow: 1;
  overflow: hidden;
}

.event-buttons {
  grid-row: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.text-uppercase {
  margin-top: 10px;
}

.event-buttons .btn {
  padding: 10px;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.btn-right {
  background-color: #d53343;
  color: white;
}

.bg-yellow {
  color:black;
  background-color: #F4D218;
  
}

.bg-primary{
  background-color: #1D9ED9;
  color:white;
}

@media (max-width: 768px) {
  .row, .event-list {
    padding: 0 10px;
  }
  .event-card {
    margin: 20px 5px;
  }
}

@media (max-width: 576px) {
  .event-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .text-uppercase {
    margin-bottom: 5px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
