import { createStore } from "vuex";

export default createStore({
    state: {
        newSession: true
    },
    mutations: {
        updateSession(state) {
            state.newSession = false
        }
    }
})