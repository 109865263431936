<style scoped>
.robot-bloc {
  background-image: url("../assets/img/4042.svg");
  background-size: cover;
  height: 100vh;
}

.robot-bloc {
  color: white !important;
}

.text-bloc {
  /* backdrop-filter: blur(4px) !important; */
  z-index: 100;
}

.main-title {
  margin-top: 13rem;
  font-size: 3.5rem;
}
.t{
    position: absolute;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(4px) !important;
    background-color: rgb(0 0 0 / 20%);
}
</style>

<template>
      <p class="t"></p>
  <div class="container">
    <div class="row p-sm-5 pt-3 robot-bloc text-black">
      <div class="col-md-6 text-bloc">
        <h1 class="main-title">Page non trouvée !</h1>
        <p class="fs-2">Cette page n'existe pas ou a été suprimée.</p>
        <p>
          <a class="btn btn-primary" href="/" role="button">
            Retourner à l'accueil
          </a>
        </p>
        <p>
          <small>© 2024 UniPod Bénin. Tous droits reservés.</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    localStorage.setItem("session_expired", "false");
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
