<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-sm-5 pt-3 pb-3">
      <div class="col">
        <div class="alert text-center" role="alert" v-if="projectPending">
          <img src="../assets/img/gear.svg" alt="Unipod Projet" />
          <h3>Votre projet est en cours de traitement.</h3>
          Revenez régulièrement vérifier la suite de votre candidature et/ou
          vérifier régulièrement votre boite mail.
        </div>
        <div class="alert text-center" role="alert" v-if="projectSelected">
          <img src="../assets/img/trophy2.svg" alt="" />
          <h1 class="congrat-title">Félicitations, votre projet est sélectionné.</h1>
        </div>
        <div class="alert text-center" role="alert" v-if="projectEliminated">
          <h4 class="mt-4">
            Votre candidature n’a pas abouti, nous vous remercions pour la
            confiance et vous invitons à proposer d'autres projets.
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const confetti = require("canvas-confetti").default;
var end = Date.now() + 15 * 600;

// go Buckeyes!
var colors = ["#0066b6", "#ffffff"];

export default {
  name: "Status",
  mounted() {
    this.getProjectStatus();
    this.frame();
  },
  data() {
    return {
      projectPending: false,
      projectSelected: false,
      projectEliminated: false,
    };
  },
  methods: {
    getProjectStatus() {
      const projectId = this.$route.params.id;

      this.$http.get(`project-status/${projectId}`).then((result) => {
        const projectStep = result.data.step;
        this.projectPending = projectStep === 1;
        this.projectSelected = projectStep === 2;
        this.projectEliminated = projectStep === -1;

        if (this.projectSelected) {
          this.frame();
        }
      });
    },

    frame() {
      if (this.projectSelected) {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors,
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors,
        });
      }

      if (Date.now() < end) {
        requestAnimationFrame(this.frame);
      }
    },
  },
  computed: {
    showRefresh() {
      if (this.projectSelected || this.projectEliminated) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.icon-folder {
  width: 25% !important;
}

@media (max-width: 575.98px) {
  .icon-folder {
    width: 50% !important;
  }
}
</style>
