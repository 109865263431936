<template>
  <div class="unipod-page" ref="unipodPage">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content mt-5">
        <h1 class="mt-5" data-aos="fade-down">À PROPOS D'UniPod</h1>
        <p data-aos="fade-up">Révolutionner les Universités Publiques d'Afrique</p>
      </div>
    </section>

    <!-- Main Content -->
    <div class="container">
      <!-- What is UniPod Section -->
      <section class="content-section font" data-aos="fade-up">
        <h2>Qu'est-ce que UniPod ?</h2>
        <div class="content-grid">
          <div class="text-content font" data-aos="fade-right">
            <p>Pour faire de la jeunesse un moteur essentiel de la croissance économique et du développement social, 
            le Bureau Régional du Programme des Nations Unies pour le développement (PNUD) pour l'Afrique a mis en 
            place en 2022 l'initiative dénommée « <strong>TIMBUKTOO</strong> », qui vise à « transformer les universités 
            publiques d'Afrique en des espaces d'innovation, d'expérimentation et d'apprentissage accéléré ».</p>
            <p>Cette initiative met en place un réseau panafricain d'innovation évolutif, constitué de <strong>Hubs</strong> 
            régionaux thématiques et de Pôles Universitaires d'Innovation <strong>(University Innovation Pod - UniPod)</strong> 
            nationaux créés au sein des universités publiques orientées sur la technologie et/ou l'innovation.</p>
          </div>
          <div class="image-content" data-aos="fade-left">
            <img src="../static/h4.png" alt="UniPod" class="rounded-image">
          </div>
        </div>
      </section>

      <!-- UniPod in Benin Section -->
      <section class="content-section font" data-aos="fade-up">
        <h2>UniPod au Bénin</h2>
        <div class="content-grid reverse">
          <div class="text-content" data-aos="fade-left">
            <p>Au Bénin, le campus de l'Université d'Abomey-Calavi accueille l'un des 18 UniPods en cours d'installation dans plusieurs 
            pays, dont le Mali, le Soudan du Sud, le Malawi, le Rwanda, l'Ouganda, la Mauritanie, la Sierra Leone, le Togo, le Libéria, 
            le Lesotho, la Guinée, la Zambie, la Gambie, le Burkina Faso, le Gabon, le Burundi, et le Botswana, avec le soutien du PNUD.</p>
            <p>Mis en place grâce au partenariat avec le Ministère de l'Enseignement Supérieur et de la Recherche Scientifique à travers 
            l'Université d'Abomey-Calavi, l'UniPod Bénin s'intègre parfaitement, suivant son modèle, dans une dynamique de complémentarité, 
            au sein de l'Université d'Abomey-Calavi et entend renforcer ses collaborations avec tous les acteurs (publics comme privés) du 
            système national d'enseignement et de recherche, de l'entreprenariat et de l'écosystème de l'innovation et de la technologie au Bénin.</p>
          </div>
          <div class="image-content" data-aos="fade-right">
            <img src="../static/hed2.png" alt="UniPod Benin" class="rounded-image">
          </div>
        </div>
      </section>

      <!-- UniPod Components Section -->
      <section class="content-section font" data-aos="fade-up">
        <h2>Composantes de l'UniPod Bénin</h2>
        <p>L'UniPod Bénin offre aux étudiantes et étudiants l'opportunité de développer leur capital humain, de maximiser leur potentiel innovatif, 
        d'encourager leur esprit entrepreneurial et d'aligner leurs connaissances avec les besoins des communautés. Il leur fournit le cadre et les outils nécessaires 
        pour exprimer pleinement leurs talents, leur créativité et leur engagement à résoudre les défis du 21e siècle auxquels le Bénin et l'Afrique sont confrontés.</p>
        <p>Situé sur le campus d'Abomey-Calavi, derrière l'ENAM, l'UniPod Bénin comprend quatre composantes clés :</p>
        <div class="components-grid font">
          <div v-for="(component, index) in components" :key="index" class="component-card" 
               :data-aos="index % 2 === 0 ? 'fade-right' : 'fade-left'"
               :data-aos-delay="index * 100">
            <h3>{{ component.title }}</h3>
            <p>{{ component.description }}</p>
          </div>
        </div>
      </section>


  <!-- Programmes de Formation UniPod Section -->
  <section class="content-section font" data-aos="fade-up">
    <h2>Programmes de Formations</h2>
    <div class="accordion-grid">
      <div v-for="(program, index) in formationPrograms" :key="index" class="accordion-item">
        <h3 class="accordion-header" :id="'formationHeading' + index">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="'#formationCollapse' + index" aria-expanded="false"
                  :aria-controls="'formationCollapse' + index">
            {{ program.title }}
          </button>
        </h3>
        <div :id="'formationCollapse' + index" class="accordion-collapse collapse"
             :aria-labelledby="'formationHeading' + index">
          <div class="accordion-body">
            {{ program.description }}
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Programmes d'accompagnement UniPod Section -->
  <section class="content-section font" data-aos="fade-up">
    <h2>Programmes d'accompagnement</h2>
    <div class="accordion-grid">
      <div v-for="(program, index) in accompagnementPrograms" :key="index" class="accordion-item">
        <h3 class="accordion-header" :id="'accompagnementHeading' + index">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="'#accompagnementCollapse' + index" aria-expanded="false"
                  :aria-controls="'accompagnementCollapse' + index">
            {{ program.title }}
          </button>
        </h3>
        <div :id="'accompagnementCollapse' + index" class="accordion-collapse collapse"
             :aria-labelledby="'accompagnementHeading' + index">
          <div class="accordion-body">
            {{ program.description }}
          </div>
        </div>
      </div>
    </div>
  </section>



    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      components: [
        { 
          title: "Un espace de coworking",
          description: "Un espace aménagé pour accueillir des travaux d'intelligence collective, de présentation, de formation et d'exhibition de groupes."
        },
        {
          title: "Un Laboratoire de conception",
          description: "Équipé d'ordinateurs dotés de logiciels professionnels spécifiques, des scanners 3D et d'imprimantes 3D pour faciliter les travaux de design industriels, électroniques et autres."
        },
        {
          title: "Un MakerSpace",
          description: "Rassemble plusieurs ateliers de fabrication technique disposant d'équipements appropriés, notamment pour le prototypage, la mécanique, le recyclage de plastique, l'énergie solaire, l'électronique, et plus encore."
        },
        {
          title: "Un Bureau de Transfert de Technologie",
          description: "Protège l'innovation, la met sur le marché et la rapproche de l'industrie et du secteur privé. Veille à l'obtention de la propriété intellectuelle et accompagne la mise en marché des produits."
        }
      ],

      formationPrograms: [
        {
          title: "Programme de formations para-académiques",
          description: "Acquérir des compétences particulières en vue de développer une ressource humaine de qualité et améliorer l'employabilité des usagers",
        },
        {
          title: "Programme d'apprentissage à l'innovation",
          description: "Renforcer et booster les capacités à innover des usagers et apprendre à tirer parti des idées, des ressources et des compétences externes pour stimuler l'innovation et accélérer le développement des produits ou services.",
        },
        {
          title: "Programme d'apprentissage à travers une plateforme technique",
          description: "Acquérir des compétences pratiques particulières pour combler le vide des formations existantes sur le plan pratique.",
        },
        {
          title: "Programme de formations intégrées aux curricula de formation existants",
          description: "Pour renforcer les compétences manquantes dans les formations académiques existantes des écoles/facultés/universités ayant un partenariat spécifique avec l'UniPod.",
        },
        {
          title: "Programme de veille technologique",
          description: "Tenir les usagers informés des dernières avancées en matière d'innovation, de sciences et de technologie.",
        },
      ],

      accompagnementPrograms: [
        {
          title: "Programme d'appui à la conceptualisation",
          description: "Aider les usagers dans le processus de conception, en mettant l'accent sur la résolution de problèmes, le perfectionnement de leur concept-projet, la création de concepts, de modèles, de produits, de services ou d'expériences",
        },
        {
          title: "Programme d'appui au prototypage",
          description: "Concrétiser les idées des usagers en les accompagnant dans la fabrication ou la production de prototypes physiques de leurs produits ou services, en utilisant les équipements disponibles à l'UniPod.",
        },
        {
          title: "Accompagnement à la propriété intellectuelle",
          description: "Faire enregistrer les innovations commercialisables à travers des démarches de dépôt de marque, dépôt de brevet, dépôt de design",
        },
        {
          title: "Accompagnement à la maturation et au transfert technologique",
          description: "Accompagner les usagers souhaitant commercialiser leurs prototypes et partager ainsi les bénéfices générés avec l'UniPod",
        },
        {
          title: 'Programme "Artisan innovant"',
          description: "Offrir à l'artisan un accompagnement complet, allant de la conceptualisation à la réalisation de prototypes commercialisables, en mettant un fort accent sur le design des produits.",
        },
        {
          title: 'Programme "Innovateur frugal"',
          description: "Soutenir et promouvoir l'innovation frugale en offrant aux innovateurs les outils et les ressources nécessaires pour concrétiser leurs idées.",
        },
      ],
    
    };
  },
  mounted() {
    this.initAOS();
  },
  methods: {
    initAOS() {
      AOS.init({
        duration: 1000,
        once: false,
        mirror: true,
        startEvent: 'DOMContentLoaded',
        delay: 50, // Ajouter un léger délai
      });
      
      this.$nextTick(() => {
        setTimeout(() => {
          AOS.refresh();
          this.staggerAOSAnimations();
        }, 100);
      });
    },
    staggerAOSAnimations() {
      const aosElements = this.$refs.unipodPage.querySelectorAll('[data-aos]');
      aosElements.forEach((el, index) => {
        setTimeout(() => {
          el.classList.add('aos-animate');
        }, index * 100); // Décaler chaque animation de 100ms
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initAOS();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$nextTick(() => {
      this.initAOS();
    });
  }
};
</script>

<style scoped>
.font{
    font-family: 'proxima-nova', sans-serif;
}
.unipod-page {
  font-family: 'Arial', sans-serif;
  color: #000000;
  line-height: 1.6;
}

.hero {
  background: url('../assets/img/bg-navbar.jpg') center/cover no-repeat;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position : relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd96f;
  z-index: 1;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero-content p {
  font-size: 1.5rem;
}

.content-section {
  padding: 3rem 0;
  border-bottom: 2px solid #F4D218;
}

h2 {
  color: #1D9ED9;
  margin-bottom: 1.5rem;
  text-align: center;
  position: relative;
}

h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #F4D218;
  margin: 10px auto;
}

/* h3::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #808285;
  margin: 10px auto;
} */

.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.reverse {
  direction: rtl;
}

.reverse > * {
  direction: ltr;
}

.text-content, .image-content {
  padding: 1rem;
}

.rounded-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.rounded-image:hover {
  transform: scale(1.05);
}

.components-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.component-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  border-left: 4px solid #1D9ED9;
}

.component-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  border-left-color: #F4D218;
}

.component-card h3 {
  color: #1D9ED9;
  margin-bottom: 1rem;
}

.accordion-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}

.accordion-item {
  flex: 1 1 calc(50% - 0.5rem);
  min-width: 250px;
  margin-bottom: 1rem;
}
.accordion-item {
  margin-bottom: 1rem;
  border: none;
}

.accordion-button {
  background-color: #ffffff;
  color: #333;
  /* font-weight: 600; */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem 1.25rem;
  transition: all 0.3s ease;
  position: relative;
}

.accordion-button:not(.collapsed) {
  background-color: #f8f9fa;
  color: #1D9ED9;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.accordion-button::after {
  content: '\2304';  /* Unicode pour une flèche vers le bas non remplie */
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: #1D9ED9;
  transition: transform 0.3s ease;
}

.accordion-button:not(.collapsed)::after {
  transform: translateY(-50%) rotate(180deg);
}

.accordion-button:hover {
  background-color: #f8f9fa;
}

.accordion-button:focus {
  box-shadow: 0 0 0 2px rgba(29, 158, 217, 0.25);
  outline: none;
}

.accordion-body {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 1rem 1.25rem;
}


@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .content-grid {
    grid-template-columns: 1fr;
  }

  .reverse {
    direction: ltr;
  }

  .components-grid {
    grid-template-columns: 1fr;
  }

  .content-section {
    padding: 2rem 0;
  }

  .accordion-button {
    font-size: 0.9rem;
  }
  
  .accordion-grid {
    grid-template-columns: 1fr;
  }
}
</style>