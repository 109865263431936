<template>
  <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">

    <div class="row py-5 bg-header" style="margin-bottom: 20px; position: relative;">
      <div class="col-12 pt-lg-5 mt-5 text-center" style="position: relative; z-index: 2;">
        <h1 class="display-4 text-white animated zoomIn mb-3"><strong>UniPod Blog</strong></h1>
        <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
        <!-- <i class="far fa-circle text-white px-2"></i> -->
        <span class="h5 text-white"> / </span>
        <span class="h5 text-white">Blog</span> <br/><br/>
      </div>
    </div>

    <div class="container py-5">
      <div class="row g-5" v-if="blogPosts.length > 0">
        <BlogCard v-for="(post, index) in displayedPosts" :key="index" :id="post.id" :slug="post.slug" :image="post.image"
        :category="getCategoryName(post.category_id)" :date="post.date" :title="post.title" :content="post.content"
        :extract="post.extract" />
      </div>
      <div v-else class="text-center">
        <p>Aucun article trouvé.</p>
      </div>
      <div class="row mt-5" v-if="shouldShowLoadMoreButton || blogPosts.length > 0">
        <div class="col-6" v-if="shouldShowLoadMoreButton">
          <button @click="loadMorePosts" class="btn btn-primary" style="background-color: #1d9ed9; text-decoration: none;">Voir plus</button>
        </div>
        <div class="col-6 text-end" :class="{ 'col-lg-6': !shouldShowLoadMoreButton }">
          <button v-if="blogPosts.length > 0" @click="scrollToTop" class="btn btn-primary" style="background-color: #1d9ed9;"><i
              class="fa fa-arrow-up"></i></button>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import BlogCard from '../components/BlogCard.vue';

export default {
  components: {
    BlogCard
  },
  data() {
    return {
      blogPosts: [],
      displayedPosts: [],
      categories: [],
      postsPerPage: 6,
      currentPage: 1
    };
  },
  computed: {
    shouldShowLoadMoreButton() {
      return this.currentPage * this.postsPerPage < this.blogPosts.length;
    },
  },
  methods: {
    loadMorePosts() {
    const startIndex = this.displayedPosts.length;
    const endIndex = startIndex + this.postsPerPage;
    const newPosts = this.blogPosts.slice(startIndex, endIndex);
    this.displayedPosts = [...this.displayedPosts, ...newPosts];
    this.currentPage++;
    },

    initDisplayedPosts() {
      this.displayedPosts = this.blogPosts.slice(0, this.postsPerPage);
      this.currentPage = 1;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    fetchblogs() {
      this.$http.get('blogs')
        .then(response => {
          this.blogPosts = response.data.reverse();
          this.initDisplayedPosts();
        })
        .catch(error => {
          console.error(error);
        });

      this.$http.get('categories')
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },

    getCategoryName(categoryId) {
      // Rechercher le nom de la catégorie correspondant à l'ID de la catégorie
      const category = this.categories.find(cat => cat.id === categoryId);
      return category ? category.name : '';
    }
  },
  mounted() {
    this.fetchblogs();
  }
};
</script>

<style scoped>
.bg-header {
  position: relative;
  background: url('../assets/img/bg-navbar.jpg') center/cover no-repeat;
  padding: 60px 0;
  transition: all 0.3s ease;
}

.bg-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd96f;
  pointer-events: none;
  transition: all 0.3s ease;
}

.bg-header:hover::before {
  background-color: #1d9dd990;
}

.display-4 {
  transition: all 0.3s ease;
}

.bg-header:hover .display-4 {
  transform: scale(1.05);
}

.btn-primary {
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(29, 158, 217, 0.3);
}

@media (max-width: 768px) {
  .bg-header {
    padding: 40px 0;
  }
  
  .display-4 {
    font-size: 2rem;
  }
}
</style>