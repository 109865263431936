<template>
  <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">

    <!-- Header -->
    <div class="row py-5 bg-header" style="margin-bottom: 20px; position: relative;">
      <div class="col-12 pt-lg-5 mt-5 text-center" style="position: relative; z-index: 2;">
        <h1 class="display-4 text-white animated zoomIn mb-3"><strong>Programmes d'Accompagnement</strong></h1>
        <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
        <span class="h5 text-white"> / </span>
        <span class="h5 banner-cta"><b>Accompagnement</b></span>
      </div>
    </div>

    <div class="py-lg-5">
      <div>
        <div v-if="events.length > 1" class="event-list">
          <SupportCard />
        </div>
        <div v-else-if="events.length === 1" class="single-event-container">
          <div class="single-event">
            <div class="event-image">
            <img :src="'https://admin.benin-unipod.bj/api/candidate/storage/' + events[0].image" :alt="events[0].title"
                class="event-image" />
              <!-- <img :src="require(`../../../eeia-api/storage/app/public/${events[0].image}`)" :alt="events[0].title"> -->
            </div>
            <div class="event-content">
              <h2>{{ events[0].title }}</h2>
              <p class="event-date">{{ formatDate(events[0].start_date, events[0].end_date) }}</p>
              <p class="event-description">{{ truncateText(events[0].title, events[0].extract, 290).extract }}</p>
              <div class="event-buttons">
                <router-link class="text-uppercase" :to="`/accompagnement/${events[0].id}/${events[0].slug}`">
                  Lire plus<i class="fa fa-chevron-right"></i>
                </router-link>
                <!-- <a :href="events[0].form_link" class="btn btn-primary">POSTULER</a> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center py-5 px-5">
          <p>Aucun programme d'accompagnement trouvé.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SupportCard from '@/components/SupportCard.vue';

  export default {
    components: {
      SupportCard,
    },
    data() {
      return {
        events: [],
      };
    },
    mounted() {
      this.fetchEventsData();
    },
    methods: {
      fetchEventsData() {
        this.$http.get('supports')
          .then(response => {
            this.events = response.data.reverse();
          })
          .catch(error => {
            console.error(error);
          });
      },
      formatDate(startDate, endDate) {
        const formattedStartDate = new Date(startDate).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
        const formattedEndDate = new Date(endDate).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });

        if (formattedStartDate === formattedEndDate) {
          return formattedStartDate;
        } else {
          return `${formattedStartDate} au ${formattedEndDate}`;
        }
      },
      truncateText(title, extract, maxLength) {
        const combinedLength = title.length + extract.length;
        if (combinedLength > maxLength) {
          return { title, extract: extract.slice(0, maxLength - title.length) + '...' };
        } else {
          return { title, extract };
        }
      }
    }
  };
</script>

<style scoped>
.font {
  font-family: 'proxima-nova', sans-serif;
  font-size: 16.5px;
}

.bg-header {
  position: relative;
  background: url('../static/accompagnement.jpg') center/cover no-repeat;
  padding: 60px 0;
}

.bg-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd96f;
  pointer-events: none;
}

.banner-cta {
  display: inline-block;
  color: #F4D218;
  padding: 2px 4px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.banner-cta:hover {
  background-color: #f4d31874;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.single-event-container {
  margin: 30px auto;
  max-width: 1200px;
  padding: 0 20px;
}

.single-event {
  display: flex;
  background-color: #eef9ffe8;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 60px;
}

.event-image {
  flex: 1;
  max-width: 50%;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-content {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.event-date {
  font-style: italic;
  color: #888;
  margin-bottom: 15px;
}

.event-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.event-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-uppercase {
  text-decoration: none;
  color: #1D9ED9;
  transition: all 0.3s ease-in-out;
}

.text-uppercase i {
  transition: transform 0.3s ease-in-out;
  margin-left: 10px;
}

.text-uppercase:hover i {
  transform: translateX(10px);
}

.btn-primary {
  background-color: #1D9ED9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-primary:hover {
  background-color: #1d9dd9b6;
  transform: translateY(-3px);
}

.event-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 1200px) {
  .event-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .banner {
    height: 400px;
  }

  .banner-text h1 {
    font-size: 2.5rem;
  }

  .banner-text p {
    font-size: 1rem;
  }

  .event-list {
    grid-template-columns: 1fr;
  }

  .single-event {
    flex-direction: column;
  }

  .event-image {
    max-width: 100%;
  }

  .event-content {
    padding: 20px;
  }

  .event-content h2 {
    font-size: 2rem;
  }

  .event-buttons {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  .text-uppercase {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .bg-header {
    position: relative;
    background: url('../static/accompagnement.jpg') center/cover no-repeat;
    height: 500px;
  }
}
</style>
