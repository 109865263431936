<template>
    <!-- Clients Start -->
    <div class="clients">
        <div class="container">
            <div class="section-header">
                <h2>Nos partenaires</h2>
                <div class="title-underline"></div>
            </div>

            <div class="logo-slider">
                <div class="logo-slide-track">
                    <div class="slide">
                        <a href="https://gouv.bj" target="_blank">
                            <img class="img1" src="../static/MESRS.png" alt="MESRS Logo">
                        </a>
                    </div>
                    <div class="slide">
                        <a href="https://uac.bj" target="_blank">
                            <img class="img3" src="../static/logo_uac.png" alt="UAC Logo">
                        </a>
                    </div>
                    <div class="slide">
                        <a href="https://www.undp.org" target="_blank">
                            <img class="img4" src="../static/timbuktoo@300x.png" alt="Timbuktoo Logo">
                        </a>
                    </div>
                    <div class="slide">
                        <a href="https://www.undp.org" target="_blank">
                            <img class="img2" src="../static/PNUD-Logo-Blue-Large.png" alt="PNUD Logo">
                        </a>
                    </div>
                    <!-- Duplicate logos for seamless loop -->
                    <div class="slide">
                        <a href="https://gouv.bj" target="_blank">
                            <img class="img1" src="../static/MESRS.png" alt="MESRS Logo">
                        </a>
                    </div>
                    <div class="slide">
                        <a href="https://uac.bj" target="_blank">
                            <img class="img3" src="../static/logo_uac.png" alt="UAC Logo">
                        </a>
                    </div>
                    <div class="slide">
                        <a href="https://www.undp.org" target="_blank">
                            <img class="img4" src="../static/timbuktoo@300x.png" alt="Timbuktoo Logo">
                        </a>
                    </div>
                    <div class="slide">
                        <a href="https://www.undp.org" target="_blank">
                            <img class="img2" src="../static/PNUD-Logo-Blue-Large.png" alt="PNUD Logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Clients End -->
</template>

<style scoped lang="css">
@import '../assets/styles/section.css';

.title-underline {
    position: relative;
    bottom: 0;
    left: 25%;
    transform: translateX(-50%);
    width: 50%;
    height: 3px;
    background-color: #1D9ED9;
    margin-top: 20px;
    animation: slideRight 3s linear infinite;
}

@keyframes slideRight {
    0% {
        transform: scaleX(0);
        transform-origin: left;
    }
    50% {
        transform: scaleX(1);
        transform-origin: left;
    }
    100% {
        transform: scaleX(0);
        transform-origin: right;
    }
}

.clients {
    padding: 40px 0;
    background: #f8f9fa;
}

.logo-slider {
    overflow: hidden;
    padding: 60px 0;
    white-space: nowrap;
    position: relative;
}

.logo-slide-track {
    display: inline-block;
    animation: slide 30s linear infinite;
}

.slide {
    display: inline-block;
    margin: 0 40px;
}

.slide img {
    max-height: 100px;
    width: auto;
    vertical-align: middle;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .slide {
        margin: 0 20px;
    }
    .slide img {
        max-height: 80px;
    }
}

@media (max-width: 480px) {
    .slide {
        margin: 0 10px;
    }
    .slide img {
        max-height: 60px;
    }
}
</style>

<script>
import AOS from 'aos';

export default {
    mounted() {
        AOS.init();
    }
}
</script>