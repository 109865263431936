<template>
  <div class="background">
    <div class="faq-container">
      <div class="title-container">
        <h2 class="faq-title">FAQs</h2>
        <hr class="deep-purple accent-2 mb-4 mt-3 mt-0 d-inline-block mx-auto" style="width: 30px;">
      </div>
      <div v-for="(question, index) in faqList" :key="index" class="faq-item">
        <div class="question" :class="{ 'active': question.open }"
          :style="{ backgroundColor: question.open ? getBackgroundColor(index) : '' }" @click="toggleAnswer(index)">
          <span class="question-text">{{ question.question }}</span>
          <span class="toggle-icon" :class="{ 'open': question.open }">
            <i :class="{ 'fas fa-chevron-down': !question.open, 'fas fa-chevron-up': question.open }"></i>
          </span>
        </div>
        <div v-show="question.open" class="answer" v-html="question.answer"></div>
      </div>
    </div>
  </div>
</template>

<script>
const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css';
document.head.appendChild(link);

export default {
  data() {
    return {
      faqList: [
        {
          question: "Qu'est-ce que le Pôle d'Innovation et de Technologie (PIT) à l'Université d'Abomey-Calavi (UAC) ?",
          answer: "Le PIT est à la fois un espace physique et une culture créative où les innovateurs potentiels, y compris les étudiants et les professeurs de toutes les disciplines, peuvent explorer leur talent de concepteur pour le développement de solutions, indépendamment de leur parcours académique.",
          open: false
        },
        {
          question: "Pourquoi le PIT-AC?",
          answer: "Pour permettre à la jeunesse estudiantine africaine de s'engager de manière significative dans l'écosystème de l'innovation, le Bureau Régional du PNUD pour l'Afrique a mis en place une initiative dénommée ''Timbuktoo''.",
          open: false
        },
        {
          question: "Qu'est-ce que l'initiative dénommée Timbuktoo ?",
          answer: "L'initiative  Timbuktoo permet de mettre en place un réseau panafricain d'innovation constitué de huit Hubs régionaux et de dix Pôles nationaux créés au sein d'universités publiques dans dix (10) pays, dont le Bénin. Au Bénin, l'Université d'Abomey-Calavi (UAC) a été retenue pour abriter l'initiative et ainsi disposer sur son campus d'un Pôle d'Innovation et de Technologie (PIT).",
          open: false
        },
        {
          question: "Comment puis-je m'impliquer au PIT en tant qu'étudiant ou professeur ?",
          answer: "Vous pouvez vous impliquer en participant aux différents programmes proposés par le PIT, en utilisant les équipements disponibles pour vos projets, et en collaborant avec d'autres membres du PIT. Pour plus d'informations, <a href='https://benin-unipod.bj/contact'>contactez-nous</a>.",
          open: false
        },
        {
          question: "Comment puis-je m'inscrire?",
          answer: "Vous pouvez vous inscrire en visitant notre page d'inscription publiée sur notre site officiel <a href='https://benin-unipod.bj'>benin-unipod.bj</a> et en remplissant le formulaire pour soumettre votre candidature.",
          open: false
        },
        {
          question: "Quels sont les dix pays participants au réseau panafricain d'innovation de l'initiative Timbuktoo ?",
          answer: "Les dix pays participants, où les Pôles nationaux sont créés au sein d'universités publiques, sont les suivants : <br> 1- BENIN ; <br> 2- GUINEA CONAKRY ; <br> 3- LESOTHO ; <br> 4- MALAWI ; <br> 5- MALI ; <br> 6- RWANDA ; <br> 7- SIERRA LEONE ; <br> 8- SOUDAN DU SUD ; <br> 9- OUGANDA ; <br> 10- TOGO ;",
          open: false
        },
        {
          question: "Quels équipements et installations sont disponibles au PIT ?",
          answer: "Le PIT dispose d'un Maker-Space+ moderne avec huit salles dédiées à des thématiques telles que le prototypage rapide, la réalisation audio-visuelle et la réalité augmentée/visuelle, l'électricité et l'électronique, le travail du bois, la greentech, la technologie agroalimentaire, et les textiles/fabriques. Il comprend également un laboratoire de conception moderne (Design Lab), un Espace de Travail Collaboratif, et un Bureau de Transfert de Technologie.",
          open: false
        },
        {
          question: "Quels sont les programmes proposés par le PIT pour les étudiantes et étudiants ?",
          answer: 'Le PIT propose plusieurs programmes, dont les "Visites de Pôle," le "Programme de Stagiaires en Ingénierie (PSI)," le "Programme Concepteurs-Innovateurs (PDI)," les "Projets Faculté-Étudiants (FSE)," et les "Programmes non universitaires (PNU)" adaptés à différents publics.',
          open: false
        },
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      // this.faqList.forEach((item, i) => {
      //   if (i !== index) {
      //     item.open = false;
      //   }
      // });
      this.faqList[index].open = !this.faqList[index].open;
    },
    getBackgroundColor(index) {
      const colors = ['#1D9ED9', '#F4D218'];
      return colors[index % colors.length];
    }
  }
};
</script>

<style scoped>
.faq-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title-container {
  text-align: center;
}

.faq-title {
  margin-top: 70px;
  text-align: center;
  font-size: 24px;
  color: #333;
}

.faq-container .faq-link {
  text-decoration: none !important;
}

.faq-item {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.question {
  padding: 15px;
  background-color: #f5f5f5;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.toggle-icon {
  margin-left: auto;
  transition: transform 0.3s;
}

.answer {
  padding: 15px;
  background-color: #fff;
  display: block;
  transition: display 0.3s;
}

.answer.show {
  display: block;
}

.background {
  background-color: #ffffff !important;
}
</style>
