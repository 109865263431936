<template>
  <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">

    <!-- Header -->
    <div class="row py-5 bg-header" style="margin-bottom: 20px; position: relative;">
      <div class="col-12 pt-lg-5 mt-5 text-center" style="position: relative; z-index: 2;">
        <h1 class="display-4 text-white animated zoomIn mb-3"><strong>Soumettre un projet</strong></h1>
        <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
        <!-- <i class="far fa-circle text-white px-2"></i> -->
        <span class="h5 text-white"> / </span>
        <span class="h5 text-white">UniPod</span> <br /><br />
      </div>
    </div>

    <div class="container py-5">

      <!-- Content -->
      <div class="row mb-4">
        <div class="col-md-6">
          <h2 class="mb-4">Fiche de collecte sur consentement des projets d’innovation technologique</h2>
          <p class="font content">
            Dans le cadre de son accompagnement au renforcement de la création de valeurs par la jeunesse béninoise à
            travers la fabrication de produits innovants répondant aux problématiques de nos communautés, l’UniPod
            (University Innovation Pod) du Bénin organise, avec l’appui du Laboratoire d’accélération du PNUD et de
            l’Université d’Abomey-Calavi (UAC), une campagne de collecte des projets d’innovation ayant fait l’objet de
            travaux de recherches de fin de formation. Ladite collecte conduira à une cartographie des meilleurs projets
            qui
            s’identifient comme des solutions et/ou innovations destinées à résoudre des problèmes ou défis qui se posent
            avec acuité à une population données. Les plus méritants bénéficieront d’un accompagnement technique pour des
            travaux de prototypage améliorés au sein des ateliers de l’UniPod, notamment du Design Lab et du MakerSpace.
          </p>
        </div>
        <div class="col-md-6 text-center">
          <img src="../assets/img/project.jpg" alt="Soumission Projet" class="img-fluid mt-4" style="width: 550px;">
        </div>
      </div>

      <div class="row">
        <div>
          <p v-if="showMore" class="font">
            Le PNUD et l’UniPod encouragent l’échange de connaissances, de solutions et d’idées entre les étudiants ayant
            soutenu leurs mémoires de fin de formation et son équipe élargie à d’autres parties prenantes concernées par
            les
            résultats des activités de l’UniPod, y compris les décideurs politiques du pays, mais aussi du monde entier,
            afin de soutenir la réalisation des Objectifs de Développement Durable (ODD).
          </p>
          <p v-if="showMore"  class="font">
            A cet effet, vous êtes invités à renseigner le présent formulaire en répondant à toutes ses questions. Ce
            formulaire comprend un avis sur votre consentement écrit ou non à ce que l’UniPod et le Laboratoire
            d’accélération puissent partager sur leurs plateformes respectives la solution innovante objet de votre
            mémoire/thèse/rapport et pour communiquer sur vous au besoin.
          </p>
          <p v-if="showMore" class="font">
            Un retour vous sera fait par l’équipe de l’UniPod si vous êtes retenu pour bénéficier d’un accompagnement dans
            l’amélioration de votre solution mise en avant sur la présente fiche.
          </p>
          <button class="btn btn-link no-underline-btn" @click="toggleShowMore">
            {{ showMore ? 'Lire moins   ' : 'Lire plus   ' }}
            <i :class="showMore ? 'fa fa-chevron-up' : 'fa fa-chevron-right'"></i>
          </button>
        </div>
      </div>

      <div>
        <router-link class="btn btn-lg submit" style="text-decoration: none;" :to="`/soumission-projet/formulaire`">Soumettre un projet</router-link>
      </div>

    </div>
  </div>
</template>

<script>
import AOS from "aos";

export default {
  data() {
    return {
      showMore: false,
    };
  },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#ffffff";
    AOS.init();
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    }
  },
};
</script>
    
<style scoped>
.font {
  font-family: 'proxima-nova', sans-serif;
  /* text-align: justify; */
  font-size: 16.5px;
}

.bg-header {
  position: relative;
  background: url('../assets/img/idee1.jpg') center/cover no-repeat;
  padding: 60px 0;
}

.bg-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd96f;
  pointer-events: none;
}

.content {
  margin-top: 30px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.submit{
  margin-top: 30px;
}
.submit {
  font-size: 1.2rem;
  padding: 10px 30px;
  background-color: #1D9ED9;
  color:white;
  border: none;
  transition: background-color 0.5s ease;
}

.submit:hover {
  background-color: #F4D218;
  color: rgba(0, 0, 0, 0.762);
}

.no-underline-btn {
  text-decoration: none;
}

@media (min-width: 992px) {
  .bg-header {
    position: relative;
    background: url('../assets/img/idee1.jpg') center/cover no-repeat;
    height: 500px;
  }
}
</style>