<template>
  <div class="events-container">
    <div class="event-card" v-for="event in cardNews" :key="event.id">
      <div class="event-image">
          <img class="img-fluid" :src="'https://admin.benin-unipod.bj/api/candidate/storage/' + event.image"
              :alt="event.title">
        <!-- <img :src="require(`../../../eeia-api/storage/app/public/${event.image}`)" :alt="event.title"> -->
      </div>
      <div class="event-content">
        <div class="event-details">
          <h2 class="event-title">{{ truncateText(event.title, event.extract, 290).title }}</h2>
          <!-- <p class="event-date">{{ formatDate(event.start_date, event.end_date) }}</p> -->
          <p class="event-description mt-3">{{ truncateText(event.title, event.extract, 290).extract }}</p>
        </div>
        <div class="event-footer">
          <router-link class="text-uppercase" :to="`/accompagnement/${event.id}/${event.slug}`">
            Lire plus<i class="fa fa-chevron-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  export default {
    data() {
      return {
        cardNews: [],
        categories: [],
      };
    },
    methods: {
      fetchAllBlogsEventsData() {
        // Utilisez ici l'appel à votre API Laravel pour récupérer les catégories
        // Par exemple, vous pouvez utiliser la bibliothèque axios pour effectuer une requête GET
        this.$http.get('SupportEvents')
          .then(response => {
            this.cardNews = response.data;
          })
          .catch(error => {
            console.error(error);
          });
  
        this.$http.get('categories')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      getCategoryName(categoryId) {
        // Rechercher le nom de la catégorie correspondant à l'ID de la catégorie
        const category = this.categories.find(cat => cat.id === categoryId);
        return category ? category.name : '';
      },
      getCategoryClass(categoryId) {
        const categoryName = this.getCategoryName(categoryId);
        return categoryName === 'Formations' ? 'bg-yellow' : 'bg-primary';
      },
      getArticleLink(id, slug, category) {
        // Construire le lien vers la page des détails de l'article avec les paramètres d'ID et de slug
        if (category === 'Formations') {
          return `/formation/${id}/${slug}`;
        } else {
          return `/formation/${id}/${slug}`;
        }
      },
      formatDate(date) {
          return new Date(date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
      },
      getRelativeDate(date) {
          const now = new Date();
          const postDate = new Date(date);
          const diffTime = Math.abs(now - postDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
          if (diffDays === 0) return "Aujourd'hui";
          if (diffDays === 1) return "Hier";
          if (diffDays < 7) return `Il y a ${diffDays} jours`;
          if (diffDays < 30) return `Il y a ${Math.floor(diffDays / 7)} semaines`;
          if (diffDays < 365) return `Il y a ${Math.floor(diffDays / 30)} mois`;
          return `Il y a ${Math.floor(diffDays / 365)} ans`;
        },
      truncateText(title, extract, maxLength) {
        const combinedLength = title.length + extract.length;
        if (combinedLength > maxLength) {
          return { title, extract: extract.slice(0, maxLength - title.length) + '...' };
        } else {
          return { title, extract };
        }
      },
      getStatus(status){
        if (status==='en_cours') return 'En cours';
        if (status==='a_venir') return 'A venir';
      }
  
    },
    mounted() {
      this.fetchAllBlogsEventsData();
    }
  };
  </script>

<style scoped>
.events-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.event-card {
  display: flex;
  background-color: #eef9ffe8;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  width: 100%;
  max-width: 1200px;
  height: 200px;
}

.event-image {
  flex: 0 0 300px;
  max-width: 300px;
  height: 100%;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden;
}

.event-details {
  overflow: hidden;
}

.event-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.event-date {
  font-style: italic;
  color: #888;
  margin-bottom: 10px;
}

.event-description {
  /* font-size: 1rem; */
  line-height: 1.4;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.event-footer {
  margin-top: auto;
  text-align: right;
}

.text-uppercase {
  text-decoration: none;
  color: #1D9ED9;
  transition: all 0.3s ease-in-out;
}

.text-uppercase i {
  transition: transform 0.3s ease-in-out;
  margin-left: 5px;
}

.text-uppercase:hover i {
  transform: translateX(5px);
}

@media (max-width: 992px) {
  .event-card {
    max-width: 95%;
    height: auto;
  }

  .event-image {
    flex: 0 0 250px;
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .event-card {
    flex-direction: column;
    height: auto;
  }

  .event-image {
    flex: none;
    max-width: 100%;
    height: 200px;
  }

  .event-content {
    padding: 15px;
  }

  .event-title {
    font-size: 1.2rem;
  }

  .event-description {
    font-size: 0.9rem;
    -webkit-line-clamp: none;
    /* margin-top: 300px; */
  }

  .event-footer {
    text-align: left;
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .events-container {
    padding: 10px;
  }

  .event-card {
    margin-bottom: 20px;
  }

  .event-image {
    height: 150px;
  }

  .event-content {
    padding: 10px;
  }

  .event-title {
    font-size: 1.1rem;
  }

  .event-date,
  .event-description,
  .text-uppercase {
    font-size: 0.8rem;
  }
}
</style>