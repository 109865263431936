<template>
  <section id="home">
    <div>
      <div>
    <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0 bg-light fixed-top">
      <router-link to="/" class="navbar-brand p-0">
        <img
          class="img-fluid m-0"
          src="../static/Unipod-logo.png"
          alt="Unipod"
          width="151"
          height="55"
        />
      </router-link>
      <!-- <button class="navbar-toggler" type="button" @click="toggleNav">
        <span class="fa fa-bars"></span>
      </button> -->
      <!-- <div class="text-center button-toggler"> -->
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <span class="fa fa-bars"></span>
          </button>
        <!-- </div> -->
      <div :class="['collapse', 'navbar-collapse', {'show': isNavOpen}]" id="navbarCollapse">
        <div class="navbar-nav mx-auto py-0">
          <router-link to="/" class="nav-link nav-item" :class="{ active: isActiveRoute('/') }">Accueil</router-link>

          <router-link to="/about" class="nav-link nav-item" :class="{ active: isActiveRoute('/about') }">A propos</router-link>

          <router-link to="/programme-accompagnement" class="nav-link nav-item" :class="{ active: isActiveRoute('/programme-accompagnement') }">Accompagnement</router-link>

          <router-link to="/programme-de-formation" class="nav-link nav-item" :class="{ active: isActiveRoute('/programme-de-formation') }">Formations</router-link>
          
          <div class="nav-item dropdown" @mouseenter="toggleDropdown('actualites', true)" @mouseleave="toggleDropdown('actualites', false)">
            <a href="#" class="nav-link dropdown-toggle" :class="{ show: isDropdownOpen.actualites, active: isActiveDropdown('actualites') }" @click.prevent="toggleDropdown('actualites', !isDropdownOpen.actualites)">
              Actualités
            </a>
            <div class="dropdown-menu" :class="{ show: isDropdownOpen.actualites }">
              <router-link class="dropdown-item" to="/blog">Blog</router-link>
              <router-link class="dropdown-item" to="/evenements">Evènements</router-link>
            </div>
          </div>
          
          <router-link to="/soumission-projet" class="nav-link nav-item" :class="{ active: isActiveRoute('/soumission-projet') }">Soumettre un projet</router-link>
          <router-link to="/contact" class="nav-link nav-item" :class="{ active: isActiveRoute('/contact') }">Nous contacter</router-link>
        </div>
        <div class="d-flex align-items-center">
        <div class="btn user-profile py-1 px-2 ms-2">
          <router-link to="/home" class="user-link">
            <div class="user-icon">
              <i class="fa fa-user-circle"></i>
            </div>
            <div class="user-name">
              <!-- {{ (lastName + ' ' + firstName).length > 24 ? ((lastName + ' ' + firstName).slice(0, 24).trim() + '...') : (lastName + ' ' + firstName) }} -->
              <span id="navbarLastName">{{ lastName }}</span>
          <br />
          <span id="navbarFirstName">{{ firstName }}</span>
            </div>
          </router-link>
        </div>
        <div class="dropdown">
    <button class="btn dropdown1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fas fa-ellipsis-v"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end menu">
      <li><router-link to="/profile" class="dropdown-item">Mon profil</router-link></li>
      <li><button class="dropdown-item" @click="logout">Se déconnecter</button></li>
    </ul>
    </div>
    </div>
        <!-- <div v-else>
          <router-link to="/register" class="btn btn-primary text-white py-1 px-2 ms-2"><span class="fas fa-user" ></span> S'inscrire</router-link>
          <router-link to="/login" class="btn btn-outline-primary py-1 px-2 ms-2"><span class="fas fa-sign-in-alt"></span> Se connecter</router-link>
        </div> -->
      </div>
    </nav>
  </div>
  
    <div class="menu-bloc">
      <nav class="navbar1 navbar-expand-lg">
        <div class="container-md justify-content-end">
          <div class="row justify-content-end">
            <div class="col-auto px-0 menu-desktop">
              <ul class="navbar-nav bg-light">
                <li class="nav-item">
                  <router-link :to="{ name: 'Home' }" class="nav-link nav-link2 nav-link-close">
                    Accueil utilisateur 
                  </router-link>
                </li>
                <li class="nav-item" v-if="hasSubmited">
                  <router-link
                    :to="{ name: 'EditCandidacy' }"
                    class="nav-link nav-link2 nav-link-close"
                  >
                    Modifier ma candidature
                  </router-link>
                </li>
                <!-- <li class="nav-item" v-else>
                  <router-link
                    :to="{ name: 'NewCandidacy' }"
                    class="nav-link nav-link1 nav-link-close"
                    id="newCandidacyLink"
                  >
                    Nouvelle candidature
                  </router-link>
                </li> -->
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'Status' }"
                    class="nav-link nav-link2 nav-link-close"
                  >
                  Mon activité
                  </router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link
                    :to="{ name: 'Preselection' }"
                    class="nav-link nav-link-close"
                  >
                    Suis-je préselectionné ?
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'Selection' }"
                    class="nav-link nav-link-close"
                  >
                    Suis-je sélectionné ?
                  </router-link>
                </li> -->
                <li class="nav-item">
                  <router-link :to="{ name: 'Profile' }" class="nav-link nav-link2 space nav-link-close">
                    Mon profil
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-auto text-white text-mobile">
              <h3>{{ currentRouteText }}</h3>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
    <div
    class="offcanvas offcanvas-start bg-light"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
    style="opacity: 0.94"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">
        <!-- Navigation -->
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="closeOffcanvas"
      ></button>
    </div>
    <div class="offcanvas-body fs-5">
      <div class="text-center">
        <p class="mb-1 fw-bold">
          <span id="navbarLastName">{{ lastName }}</span>
          <br />
          <span id="navbarFirstName">{{ firstName }}</span>
        </p>
        <button
          class="btn btn-outline-primary mt-1 disconnect-button"
          type="button"
          @click="logout"
        >
          Me déconnecter
        </button>
      </div>
      <hr />
      <div>
        <ul class="navbar-nav bg-light">
          <li class="nav-item">
            <router-link :to="{ name: 'Home1' }" class="nav-link nav-link1 nav-link-close">
              Accueil
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Home' }" class="nav-link nav-link1 nav-link-close">
              Accueil utilisateur
            </router-link>
          </li>
          <li class="nav-item" v-if="hasSubmited">
            <router-link :to="{ name: 'EditCandidacy' }" class="nav-link nav-link1 nav-link-close">
              Modifier ma candidature
            </router-link>
          </li>
          <!-- <li class="nav-item" v-else>
            <router-link :to="{ name: 'NewCandidacy' }" class="nav-link nav-link1 nav-link-close" id="newCandidacyLink">
              Nouvelle candidature
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link :to="{ name: 'Status' }" class="nav-link nav-link1 nav-link-close">
              Mon activité
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{ name: 'Preselection' }" class="nav-link1 nav-link-close">
              Suis-je préselectionné ?
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Selection' }" class="nav-link1 nav-link-close">
              Suis-je sélectionné ?
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link :to="{ name: 'Profile' }" class="nav-link nav-link1 nav-link-close">
              Mon profil
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Contact' }" class="nav-link nav-link1 nav-link-close">
              Nous contacter
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'About' }" class="nav-link nav-link1 nav-link-close">
              À propos
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
  props: {
    updateNav: Number,
  },

  mounted() {
    this.isLogin = localStorage.getItem("token") ? true : false;

    var elements = document.getElementsByClassName("nav-link-close");

    this.setCurrentRouteText();

    var myFunction = function () {
      document.querySelector("#closeOffcanvas").click();
    };

    Array.from(elements).forEach(function (element) {
      element.addEventListener("click", myFunction);
    });
  },
  data() {
    return {
      isNavOpen: false,
      isDropdownOpen: {
        services: false,
        actualites: false
      },
      categories: [],
      isLogin: false,
      currentRouteText: "Plateforme de candidature",
      // hasSubmited: localStorage.getItem('candidature_step') >= 3 
    };
  },

  watch: {
    $route(newRouteValue) {
      this.currentRouteText = newRouteValue.meta.frenchName; // {name: "About", meta: {…}, path: "/about", hash: "", query: {…}, …}
    },
  },

    methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    toggleDropdown(dropdown, status) {
      this.isDropdownOpen[dropdown] = status;
    },
    isActiveRoute(route) {
      return this.$route.path === route;
    },
    isActiveDropdown(dropdown) {
      const routes = {
        services: ['/formation', '/accompagnement'],
        actualites: ['/blog', '/evenements']
      };
      return routes[dropdown].some(route => this.$route.path.startsWith(route));
    },
    logout() {
      this.$http.post("logout").then(() => {});
      document.querySelector("#closeOffcanvas").click();
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("candidature_step");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("userId");
      this.$router.push("login");
    },
    setCurrentRouteText() {
      if (document.querySelector(".router-link-active")) {
        this.currentRouteText = document.querySelector(".router-link-active").textContent;
      }
    },
  },

  computed: {
    firstName: function () {
      return localStorage.getItem("firstName") ? localStorage.getItem("firstName") : "";
    },
    lastName: function () {
      return localStorage.getItem("lastName") ? localStorage.getItem("lastName") : "";
    },
    hasSubmited: function () {
      // if (localStorage.getItem('candidature_step') >= 3) {
      //   return true
      // } else if(this.updateNav > 0) {
      //   return true
      // } else { 
      //   return false
      // }
      return false
    }
  },
};
</script>

<style scoped>
:root {
  --primary: #1D9ED9;
}
body {
  background-color: #1d9ed9;
}

/* btn */
.btn {
    font-family: 'Poppins', regular;
    font-weight: 15px;
    transition: .5s;
    color: #1D9ED9;
}


.btn-outline-primary:hover {
  color: white;
  transition: color 0.5s ease;
}


.user-profile {
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  border-color: #1D9ED9;
  margin-right: 15px;
  font-size: 15px;
}
.user-profile:hover {
  transform: scale(0.95);
}

.user-link {
  display: flex;
  font-family: 'Poppins', regular;
  font-weight: 15px;
  transition: .5s;
  align-items: center;
  text-decoration: none;
  color: #1D9ED9;
}

.user-icon {
  margin-right: 6px;
  color: #1d9dd92f;
}

.user-icon i {
  font-size: 25px;
}

.user-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/*** Navbar ***/
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.navbar-dark .navbar-nav .nav-link {
    font-family: 'Poppins', regular;
    position: relative;
    margin-left: 25px;
    padding: 10px 10px;
    color: #1D9ED9;
    font-size: 15px;
    outline: none;
    transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 20px 0;
    color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar-dark .navbar-brand h1 {
    color: #FFFFFF;
}

.navbar-dark .navbar-toggler {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
}
.dropdown-item{
  font-family: 'Poppins', regular;
    position: relative;
    /* margin-left: 25px; */
    padding: 10px 10px;
    color: #1D9ED9;
    font-size: 15px;
    outline: none;
    transition: .5s;
}

.offcanvas .router-link-exact-active {
  font-weight: bold;
  padding-left: 4px;
  border-left: 3px solid #3490dc;
  border-bottom: unset;
}

.nav-link1:hover {
  border-bottom: 3px solid #3490dc;
}

.nav-link2:hover {
  border-bottom: 3px solid #3490dc;
}

/* ///////////////////////////// */
.menu-bloc {
  margin-top:60px;
  height: 140px;
  background-image: url("../assets/img/bg-navbar.jpg");
}

.navbar1 {
  padding-top: 6.3rem !important;
}

.text-mobile {
  display: none;
}

.dropdown1 {
  border: none;
  border-radius: 30%;
}

.dropdown1:hover,
.dropdown1:focus {
  background-color: #cccccc3d;
}

.dropdown1 i {
  color: #3490dc;
}

.menu{
  margin-top:12px;
}

.nav-link2 {
    font-family: 'Poppins', regular;
    position: relative;
    margin-left: 20px;
    padding: 10px 10px;
    font-size: 15px;
    outline: none;
    transition: .5s;
  }

  .menu-desktop {
  margin-right: 13px;
  /* margin-top: 444px; */
 }

  .menu-desktop .router-link-exact-active {
  font-weight: bold;
  /* padding-left: 4px; */
  border-bottom: 3px solid #3490dc;
}

.space{
  margin-right: 20px;
}
/* ///////////////////////////// */

@media (min-width: 576px) and (max-width: 991px) {
    .sticky-top.navbar-dark {
        position: relative;
        background: #FFFFFF;
    }

    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .sticky-top.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        color: var(--dark);
    }

    .navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }

    .menu-desktop {
    display: none;
  }

  .text-mobile {
    display: block;
  }
}

@media (max-width: 576px) {
    .navbar-dark {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgba(256, 256, 256, .1);
        z-index: 999;
    }
    
    .sticky-top.navbar-dark {
        position: fixed;
        background: #FFFFFF;
    }

    .navbar-dark .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        bottom: -1px;
        left: 50%;
        background: var(--primary);
        transition: .5s;
    }

    .navbar-dark .navbar-nav .nav-link:hover::before,
    .navbar-dark .navbar-nav .nav-link.active::before {
        width: 100%;
        left: 0;
    }

    .navbar-dark .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }

    .sticky-top.navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }

    .menu-desktop {
    display: none;
  }

  .text-mobile {
    display: block;
  }
}
</style>