<template>
  <div class="container px-sm-0 ">
    <div class="row p-sm-5 pt-3 pb-3">
      <div class="col">
        <div class="alert text-center" role="alert" v-if="userProjects.length > 0">
          <h4>Projets soumis :</h4>
          <div v-for="project in userProjects" :key="project.id">
            <ProjectCard :project="project" />
          </div>
        </div>
        <div class="alert text-center" role="alert" v-else>
          <div class="m-auto icon-folder">
            <img src="../assets/img/folder.png" alt="" class="img-fluid" />
          </div>
          <h3>Aucun projet soumis.</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from '../components/ProjectCard.vue';

export default {
  name: "Status",
  mounted() {
    this.getUser();
  },
  data() {
    return {
      notSubmited: false,
      pending: false,
      preselected: false,
      selected: false,
      eliminated: false,
      submitedProject: false,
      userProjects: [],
    };
  },
  components: {
    ProjectCard
  },
  methods: {
    getUser() {
      this.$http.get("users/000").then((result) => {
        localStorage.setItem("candidature_step", result.data.step);
        this.notSubmited = result.data.step < 1 && result.data.step == 0;
        this.pending = result.data.step == 1;
        this.preselected = result.data.step == 4;
        this.selected = result.data.step == 5;
        this.eliminated = result.data.step == -1;

        if (this.selected) {
          this.frame()
        }
      });
      this.$http.get("check-status").then((result) => {
        this.submitedProject = result.data.submitedProject;
        this.userProjects = result.data.userProjects.reverse();
        console.log(this.userProjects);
      });
    },
  },
  computed: {
    showRefresh() {
      if (this.selected || this.eliminated) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.icon-folder {
  width: 25% !important;
}

@media (max-width: 575.98px) {
  .icon-folder {
    width: 50% !important;
  }
}
</style>
