<template>
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div class="row py-5 bg-header" style="margin-bottom: 20px; position: relative;">
        <div class="col-12 pt-lg-5 mt-5 text-center" style="position: relative; z-index: 2;">
          <h1 class="display-4 text-white animated zoomIn mb-3"><strong>UniPod Blog</strong></h1>
          <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
          <span class="h5 text-white"> / </span>
          <a href="/blog" class="h5 text-white" style="text-decoration: none;">Blog</a>
          <!-- <span class="h5 text-white"> / </span>
          <span class="h5 text-white">{{ truncateText(post.title, 12) }}</span> -->
        </div>
      </div>
  
      <div class="container-fluid2 py-5">
        <div class="row">
          <!-- Article principal -->
          <div class="col-lg-8">
            <div class="article-content">
              <h2 class="title mb-2"><strong>{{ post.title }}</strong></h2>
              <p class="text-muted mb-4">{{ getRelativeDate(post.date) }}</p>
              <div class="image-container mb-4">
                <img :src="imageURL" :alt="post.title" class="card-image-slide img-fluid w-100">
              </div>
              <div class="mb-3">
                <span class="me-3"><i class="far fa-calendar-alt text-primary me-2"></i>{{ formatDate(post.date) }}</span>
                <span class="me-3"><i class="far fa-folder-open text-primary me-2"></i>{{ getCategoryName(post.category_id) }}</span>
              </div>
              <div class="content font" v-html="post.content"></div>
  
              <div class="share-buttons mt-5">
                <span class="share-label">Partager</span>
                <div class="share-icons">
                  <a :href="shareOnFacebook()" target="_blank" class="share-button">
                    <i class="fab fa-facebook"></i>
                  </a>
                  <a :href="shareOnTwitter()" target="_blank" class="share-button">
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a :href="shareOnLinkedIn()" target="_blank" class="share-button">
                    <i class="fab fa-linkedin"></i>
                  </a>
                  <a href="#" class="share-button" @click="shareByEmail">
                    <i class="fa fa-envelope"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Articles connexes -->
          <div class="col-lg-4" v-if="relatedArticles.length > 0">
            <div class="related-articles">
              <h3 class="mb-4">Articles connexes</h3>
              <div v-for="article in relatedArticles" :key="article.id" class="related-article-card mb-3">
                <div class="row g-0">
                  <div class="col-4">
                    <img :src="getImageUrl(article.image)" class="img-fluid rounded-start" :alt="article.title">
                  </div>
                  <div class="col-8">
                    <div class="card-body">
                      <h5 class="card-title">{{ article.title }}</h5>
                      <a :href="'/blog/' + article.id + '/' + article.slug" class="btn btn-primary btn-sm mt-2">Lire plus</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import AOS from "aos";
  
  export default {
    data() {
      return {
        post: {},
        imageURL: '',
        relatedArticles: [],
        categories: []
      };
    },
    mounted() {
      const id = this.$route.params.id;
      const slug = this.$route.params.slug;
      this.getArticleById(id, slug);
      this.fetchCategories();
      document.querySelector("body").style.backgroundColor = "#ffffff";
      AOS.init();
    },
    methods: {
      getArticleById(id, slug) {
        this.$http.get(`blog/${id}/${slug}`)
          .then(response => {
            this.post = response.data;
            this.fetchImage();
            this.fetchRelatedArticles();
          })
          .catch(error => {
            console.error(error);
          });
      },
      truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + '...';
        } else {
          return text;
        }
      },
      fetchImage() {
        if (this.post.image) {
          const baseURL = "https://admin.benin-unipod.bj/api/candidate";
          // const baseURL = "http://127.0.0.1:8000/api/candidate";
          const url = `storage/${this.post.image}`;
          this.imageURL = `${baseURL}/${url}`;
        } else {
          console.error('Aucune image n\'est définie pour cet article.');
        }
      },
      getImageUrl(imagePath) {
        const baseURL = "https://admin.benin-unipod.bj/api/candidate";
        // const baseURL = "http://127.0.0.1:8000/api/candidate";
        return `${baseURL}/storage/${imagePath}`;
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
      },
      getRelativeDate(date) {
        const now = new Date();
        const postDate = new Date(date);
        const diffTime = Math.abs(now - postDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
        if (diffDays === 0) return "Aujourd'hui";
        if (diffDays === 1) return "Hier";
        if (diffDays < 7) return `Il y a ${diffDays} jours`;
        if (diffDays < 30) return `Il y a ${Math.floor(diffDays / 7)} semaines`;
        if (diffDays < 365) return `Il y a ${Math.floor(diffDays / 30)} mois`;
        return `Il y a ${Math.floor(diffDays / 365)} ans`;
      },
      shareOnFacebook() {
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
      },
      shareOnTwitter() {
        const shareText = encodeURIComponent(this.post.title);
        return `https://twitter.com/intent/tweet?text=${shareText}%0A&url=${encodeURIComponent(window.location.href)}`;
      },
      shareOnLinkedIn() {
        return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.post.title)}&summary=${encodeURIComponent(this.post.extract)}&source=`;
      },
      shareByEmail() {
        const subject = encodeURIComponent(this.post.title);
        const body = encodeURIComponent(`${this.post.extract}\n\nLire plus: ${window.location.href}`);
        const shareURL = `mailto:?subject=${subject}&body=${body}`;
        window.open(shareURL, '_blank');
      },
      fetchRelatedArticles() {
        this.$http.get('blogs')
          .then(response => {
            const allPosts = response.data;
            this.relatedArticles = allPosts
              .filter(article => article.id !== this.post.id)
              .sort(() => 0.5 - Math.random())
              .slice(0, 4);
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des articles connexes:', error);
          });
      },
      fetchCategories() {
        this.$http.get('categories')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      getCategoryName(categoryId) {
        const category = this.categories.find(cat => cat.id === categoryId);
        return category ? category.name : '';
      }
    }
  };
  </script>
  
  <style scoped>
  .container-fluid2 {
    padding-left: 200px;
    padding-right: 70px;
  }
  
  .bg-header {
    /* background-color: #1D9ED9; */
    background: url('../assets/img/bg-navbar.jpg') center/cover no-repeat;
    color: white;
  }
  .bg-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd9ab;
  pointer-events: none;
}
  
  .title {
    color: #1D9ED9;
    /* border-bottom: 3px solid #F4D218; */
    /* padding-bottom: 10px; */
  }
  
  .card-image-slide {
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .font {
    font-family: 'proxima-nova', sans-serif;
  }
  
  .content {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .share-buttons {
    margin-top: 2rem;
  }
  
  .share-label {
    color: #1D9ED9;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    display: block;
  }
  
  .share-icons {
    display: flex;
    gap: 1rem;
  }
  
  .share-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #808285;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
  }
  
  .share-button:hover {
    background-color: #1D9ED9;
  }
  
  .share-button i {
    color: white;
    font-size: 1.2rem;
  }
  
  .related-articles {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .related-article-card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .related-article-card:hover {
    transform: translateY(-5px);
  }
  
  .related-article-card .card-body {
    padding: 0.5rem 1rem;
  }
  
  .related-article-card .card-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .related-article-card .btn-primary {
    background-color: #1D9ED9;
    border-color: #1D9ED9;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
  }
  
  .related-article-card .btn-primary:hover {
    background-color: #F4D218;
    border-color: #F4D218;
    color: black;
  }
  
  .related-article-card img {
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 1200px) {
    .container-fluid2 {
      padding-left: 100px;
      padding-right: 50px;
    }
    .related-articles {
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 991px) {
    .container-fluid2 {
      padding-left: 50px;
      padding-right: 50px;
    }
    .related-articles {
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .container-fluid2 {
      padding-left: 15px;
      padding-right: 15px;
    }
    .title {
      font-size: 2rem;
    }
    .content {
      font-size: 1em;
    }
  }
  
  @media (max-width: 576px) {
    .title {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 1200px) {
    .related-articles {
      margin-top: 9rem;
    }
  }
  </style>