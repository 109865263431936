<template>
    <div>
      <!-- <div class="banner">
        <div class="banner-text">
          <h1>Événements à venir</h1>
          <p>Découvrez nos prochains événements passionnants. Rejoignez-nous maintenant !</p>
        </div>
        <div class="banner-image">
          <img src="../static/idea2.jpeg" alt="Image bannière" />
        </div>
      </div> -->

      <div class="banner">
      <div class="banner-content">
        <div class="banner-text">
          <h1>Événements à venir</h1>
          <p>Découvrez nos prochains événements passionnants. Rejoignez-nous maintenant !</p>
          <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
          <!-- <i class="far fa-circle text-white px-2"></i> -->
          <span class="h5 text-white"> / </span>
          <span class="h5 banner-cta"><b>Evénements</b></span>
          <br /><br />
        </div>
      </div>
    </div>

    <div class="container">
      <div>
        <div v-if="events.length > 1" class="event-list">
          <EventCard v-for="event in events" :key="event.id" :event="event" />
        </div>
        <div v-else-if="events.length === 1" class="single-event-container">
          <div class="single-event">
            <div class="event-image">
            <img :src="'https://admin.benin-unipod.bj/api/candidate/storage/' + events[0].image" :alt="events[0].title"/>
              <!-- <img :src="require(`../../../eeia-api/storage/app/public/${events[0].image}`)" :alt="events[0].title"> -->
            </div>
            <div class="event-content">
              <h2>{{ events[0].title }}</h2>
              <p class="event-date">{{ formatDate(events[0].start_date, events[0].end_date) }}</p>
              <p class="event-description">{{ truncateText(events[0].title, events[0].extract, 290).extract }}</p>
              <div class="event-buttons">
                <router-link class="text-uppercase" :to="`/evenement/${events[0].id}/${events[0].slug}`">
                  Lire plus<i class="fa fa-chevron-right"></i>
                </router-link>
                <!-- <a :href="events[0].form_link" class="btn btn-primary">POSTULER</a> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center py-5 px-5">
          <p>Aucun événement trouvé.</p>
        </div>
      </div>
    </div>
  
  </div>
</template>
  
  <script>
  import EventCard from '@/components/EventCard.vue';
  
  export default {
    components: {
      EventCard,
    },
    data() {
      return {
        events: [],
      };
    },
    mounted() {
      this.fetchEventsData();
    },
    methods: {
      fetchEventsData() {
        this.$http.get('events')
          .then(response => {
            this.events = response.data.reverse();
          })
          .catch(error => {
            console.error(error);
          });
      },
      formatDate(startDate, endDate) {
        const formattedStartDate = new Date(startDate).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
        const formattedEndDate = new Date(endDate).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
  
        if (formattedStartDate === formattedEndDate) {
          return formattedStartDate;
        } else {
          return `${formattedStartDate} au ${formattedEndDate}`;
        }
      },
      truncateText(title, extract, maxLength) {
        const combinedLength = title.length + extract.length;
        if (combinedLength > maxLength) {
          return { title, extract: extract.slice(0, maxLength - title.length) + '...' };
        } else {
          return { title, extract };
        }
      }
    }
  };
  </script>
  
  <style scoped>
  
  /* .banner-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
  } */
  
  /* .banner-image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  } */
  
  /* .banner-image img {
    max-width: 100%;
    max-height: 350px;
  } */
  
  .event-list {
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .banner {
  background-image: url('../static/h4.png'); 
  background-size: cover;
  background-position: center;
  height: 390px;
  position: relative;
  margin-top: 69px;
}

.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.banner-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 0 20px;
}

.banner-text {
  max-width: 800px;
}

.banner-text h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.banner-text p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.banner-cta {
  display: inline-block;
  color: #F4D218;
  padding: 2px 4px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.banner-cta:hover {
  background-color: #f4d31874;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
  
  .single-event-container {
    margin: 30px auto;
    max-width: 1200px;
    padding: 0 20px;
  }
  
  .single-event {
    display: flex;
    background-color: #eef9ffe8;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
  }
  
  .event-image {
    flex: 1;
    max-width: 50%;
  }
  
  .event-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .event-content {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .event-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .event-date {
    font-style: italic;
    color: #888;
    margin-bottom: 15px;
  }
  
  .event-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .event-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .text-uppercase {
    text-decoration: none;
    color: #1D9ED9;
    /* font-weight: bold; */
    transition: all 0.3s ease-in-out;
  }
  
  .text-uppercase i {
    transition: transform 0.3s ease-in-out;
    margin-left: 10px;
  }
  
  .text-uppercase:hover i {
    transform: translateX(10px);
  }
  
  .btn-primary {
    background-color: #1D9ED9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .btn-primary:hover {
    background-color: #1d9dd9b6;
    transform: translateY(-3px);
  }
  
  @media (max-width: 768px) {

@media (max-width: 768px) {
  .banner {
    height: 400px;
  }

  .banner-text h1 {
    font-size: 2.5rem;
  }

  .banner-text p {
    font-size: 1rem;
  }
}
    .single-event {
      flex-direction: column;
    }
  
    .event-image {
      max-width: 100%;
    }
  
    .event-content {
      padding: 20px;
    }
  
    .event-content h2 {
      font-size: 2rem;
    }
  
    .event-buttons {
      flex-direction: column;
      align-items: stretch;
      gap: 15px;
    }
  
    .text-uppercase {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  </style>