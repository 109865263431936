<template>
    <div class="container-fluid feature pt-5">
      <div class="container pt-5">
        <div class="row g-5">
          <div class="col-lg-6 align-self-center mb-md-5 pb-md-5" data-aos="fade-right">
            <!-- <div class="btn btn-sm border rounded-pill text-white px-3 mb-3" style="background-color: #F4D218;font-weight: bold;">UniPod Bénin</div> -->
            <h1 class="text-white mb-4">Un espace d'innovation, ouvert à tous : étudiant(e)s, autodidactes, entrepreneurs</h1>
            <p class="text-light mb-4">Transformer les universités publiques d'Afrique en des espaces d'innovation, d'expérimentation et de transformation accélérée</p>
            
            <div class="feature-list">
              <div class="feature-item" v-for="(item, index) in featureItems" :key="index">
                <div class="icon-box">
                  <i :class="item.icon"></i>
                </div>
                <span>{{ item.text }}</span>
              </div>
            </div>
  
            <div class="row g-4 pt-3">
              <div class="col-sm-6" v-for="(stat, index) in stats" :key="index">
                <div class="stat-box">
                  <i :class="stat.icon"></i>
                  <div class="stat-content">
                    <h2 class="text-white mb-0">
                      <count-up v-if="stat.number.toString().startsWith('Plus de')"
                                :end-val="400"
                                :duration="2.5"
                                :options="{ prefix: 'Plus de ' }"
                      />
                      <template v-else>{{ stat.number }}</template>
                    </h2>
                    <p class="text-white mb-0">{{ stat.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 align-self-end text-center text-md-end" data-aos="fade-left">
            <img class="img-fluid hero" src="../assets/img/UniPodHero.png" alt="UniPod Hero">
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CountUp from 'vue-countup-v3'
  
  export default {
    components: {
      CountUp
    },
    data() {
      return {
        featureItems: [
          { icon: 'fa fa-check', text: 'Un Espace de Travail Collaboratif' },
          { icon: 'fa fa-check', text: 'Un Laboratoire de Conception moderne (Design, fabrication numérique)' },
          { icon: 'fa fa-check', text: 'Un Maker-Space+ moderne' },
        ],
        stats: [
        { icon: 'fa fa-check', number: 'Programmes de formation', text: '' },
          { icon: 'fa fa-plus', number: 'Conceptualisation et Prototypage', text: '' },
          { icon: 'fa fa-users', number: 'Plus de 400', text: 'Visiteurs dans nos locaux' },
          { icon: 'fa fa-check', number: 'Veille technologique', text: '' },
          
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .feature {
    background-color: #1D9ED9;
    background-image: linear-gradient(rgba(29, 157, 217, 0.8), rgba(29, 157, 217, 0.8)), url('../assets/img/bg-hero.png');
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .feature-list .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .feature-list .icon-box {
    background-color: #F4D218;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
  
  .feature-list .icon-box i {
    color: #1D9ED9;
  }
  
  .stat-box {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .stat-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .stat-box i {
    font-size: 2rem;
    margin-right: 1rem;
    color: white;
  }
  
  .stat-content h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .img-fluid.hero {
    max-width: 100%;
    height: auto;
    animation: float 6s ease-in-out infinite;
  }
  
  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
  }
  </style>