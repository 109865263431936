<template>
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">

        <!-- Header -->
        <div class="row py-5 bg-header" style="margin-bottom: 20px; position: relative;">
            <div class="col-12 pt-lg-5 mt-5 text-center" style="position: relative; z-index: 2;">
                <h1 class="display-4 text-white animated zoomIn mb-3"><strong>Soumettre un projet</strong></h1>
                <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
                <!-- <i class="far fa-circle text-white px-2"></i> -->
                <span class="h5 text-white"> / </span>
                <span class="h5 text-white">UniPod</span> <br /><br />
            </div>
        </div>

        <div class="form-container container py-5">
          <div class="row">
              <VeeForm
                class="col"
                v-slot="{ handleSubmit }"
                :validation-schema="schema"
                as="div"
              >
                <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
                  <div class="step-progress">
                    <div v-for="step in 4" :key="step" 
                        :class="['step-indicator', 
                                  { 'active': currentStep === step, 
                                    'completed': currentStep > step }]">
                      {{ step }}
                    </div>
                  </div>
                <form
                  @submit="handleSubmit($event, onSubmit)"
                  class="row"
                  id="projetForm"
                  ref="projetForm"
                >
                  <div v-if="currentStep === 1" class="col">
                    <h2>Étape 1 : Niveau académique</h2>
                    <fieldset>
                      <legend>Niveau académique :</legend>
                      <div class="form-check">
                        <Field
                          name="niveau_academique"
                          type="radio"
                          value="doctorat"
                          v-model="niveau_academique"
                          v-slot="{ field, errorMessage }"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            id="doctorat"
                            v-bind="field"
                            :class="{ 'is-invalid': errorMessage }"
                          />
                          <label class="form-check-label" for="doctorat">Doctorat</label>
                        </Field>
                      </div>
                      <div class="form-check">
                        <Field
                          name="niveau_academique"
                          type="radio"
                          value="master"
                          v-model="niveau_academique"
                          v-slot="{ field, errorMessage }"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            id="master"
                            v-bind="field"
                            :class="{ 'is-invalid': errorMessage }"
                          />
                          <label class="form-check-label" for="master">Master</label>
                        </Field>
                      </div>
                      <div class="form-check">
                        <Field
                          name="niveau_academique"
                          type="radio"
                          value="licence"
                          v-model="niveau_academique"
                          v-slot="{ field, errorMessage }"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            id="licence"
                            v-bind="field"
                            :class="{ 'is-invalid': errorMessage }"
                          />
                          <label class="form-check-label" for="licence">Licence</label>
                        </Field>
                      </div>
                      <div class="form-check">
                        <Field
                          name="niveau_academique"
                          type="radio"
                          value="non_universitaire"
                          v-model="niveau_academique"
                          v-slot="{ field, errorMessage }"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            id="non_universitaire"
                            v-bind="field"
                            :class="{ 'is-invalid': errorMessage }"
                          />
                          <label class="form-check-label" for="non_universitaire">Non-Universitaires</label>
                        </Field>
                          <ErrorMessage name="niveau_academique" />
                      </div>
                    </fieldset>
                    <button @click="nextStep" :disabled="!niveau_academique" type="button" class="btn btn-primary">Suivant</button>
                  </div>

            <div v-if="currentStep === 2 && niveau_academique !== 'non_universitaire'">
              <h2>Étape 2 : Informations personnelles</h2>
              <!-- Section for Academic Users -->
              <fieldset v-if="niveau_academique !== 'non_universitaire'">
                <div class="input-wrapper mb-3">
                  <label for="annee_soutenance" class="form-label">Année de soutenance :</label>
                  <label class="required"></label>
                  <Field
                    name="annee_soutenance"
                    as="select"
                    v-model="annee_soutenance"
                    class="form-select"
                  >
                    <option value="">Sélectionner l'année</option>
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </Field>
                  <ErrorMessage name="annee_soutenance" class="invalid-feedback" />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="noms_candidats" class="form-label">Nom(s) et prénom(s) :</label>
                  <label class="required"></label>
                  <Field
                    name="noms_candidats"
                    type="text"
                    label="noms_candidats"
                    v-model="noms_candidats"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="noms_candidats"
                      v-bind="field"
                      placeholder="Entrer les noms et prénoms"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="2"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="noms_candidats"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="email" class="form-label">Email :</label>
                  <label class="required"></label>
                  <Field
                    name="email"
                    type="email"
                    label="email"
                    v-model="email"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      id="email"
                      v-bind="field"
                      placeholder="Entrer l'email"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                  </Field>
                  <ErrorMessage
                    name="email"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="telephone" class="form-label">Téléphone :</label>
                  <label class="required"></label>
                  <Field
                    name="telephone"
                    type="text"
                    label="telephone"
                    v-model="telephone"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      id="telephone"
                      v-bind="field"
                      placeholder="Entrer le numéro de téléphone"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                  </Field>
                  <ErrorMessage
                    name="telephone"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="autre_coordonnee" class="form-label">Autre coordonnée :</label>
                  <Field
                    name="autre_coordonnee"
                    type="text"
                    label="autre_coordonnee"
                    v-model="autre_coordonnee"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      id="autre_coordonnee"
                      v-bind="field"
                      placeholder="Entrer une autre coordonnée (optionnel)"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                  </Field>
                  <ErrorMessage
                    name="autre_coordonnee"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="directeur_encadreur" class="form-label">Encadreur :</label>
                  <label class="required"></label>
                  <Field
                    name="directeur_encadreur"
                    type="text"
                    label="directeur_encadreur"
                    v-model="directeur_encadreur"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      id="directeur_encadreur"
                      v-bind="field"
                      placeholder="Entrer le nom de l'encadreur"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                  </Field>
                  <ErrorMessage
                    name="directeur_encadreur"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="etablissement_entite" class="form-label">Établissement :</label>
                  <label class="required"></label>
                  <Field
                    name="etablissement_entite"
                    type="text"
                    label="etablissement_entite"
                    v-model="etablissement_entite"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      id="etablissement_entite"
                      v-bind="field"
                      placeholder="Entrer l'établissement de l'obtention du diplome"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                  </Field>
                  <ErrorMessage
                    name="etablissement_entite"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>
                <div class="input-wrapper mb-3">
                  <label for="domaines_application" class="form-label">Domaines d'application du projet:</label>
                  <label class="required"></label>
                  <div v-for="domain in domainOptions" :key="domain">
                    <input
                      type="checkbox"
                      :id="domain"
                      :value="domain"
                      v-model="domaines_application"
                    />
                    <label :for="domain">{{ domain }}</label>
                  </div>
                  <ErrorMessage
                    name="domaines_application"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="titre_memoire" class="form-label">Libellé du mémoire ou thèse:</label>
                  <label class="required"></label>
                  <Field
                    name="titre_memoire"
                    type="text"
                    label="titre_memoire"
                    v-model="titre_memoire"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="titre_memoire"
                      v-bind="field"
                      placeholder="Entrer le libellé"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="2"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="titre_memoire"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="form-check">
                  <Field
                    name="autorisation_collecte"
                    type="radio"
                    value="oui"
                    v-model="autorisation_collecte"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="autorisation_oui"
                      v-bind="field"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                    <label class="form-check-label" for="autorisation_oui">
                      Oui, j'autorise la collecte d'informations sur ma solution innovante
                    </label>
                  </Field>
                </div>
                <div class="form-check">
                  <Field
                    name="autorisation_collecte"
                    type="radio"
                    value="non"
                    v-model="autorisation_collecte"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="autorisation_non"
                      v-bind="field"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                    <label class="form-check-label" for="autorisation_non">
                      Non, je ne souhaite pas autoriser la collecte d'informations
                    </label>
                  </Field>
                </div>
                <div class="input-wrapper mb-3" v-if="autorisation_collecte === 'non'">
                  <label for="reserve_collecte" class="form-label">Préciser vos réserves :</label>
                  <Field
                    name="reserve_collecte"
                    type="text"
                    label="reserve_collecte"
                    v-model="reserve_collecte"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="reserve_collecte"
                      v-bind="field"
                      placeholder="Expliquer vos réserves"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="2"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="reserve_collecte"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>
                <ErrorMessage
                  name="autorisation_collecte"
                  as="div"
                  class="invalid-feedback"
                />
              </fieldset>
                <button @click="previousStep" class="btn btn-secondary">Précédent</button>
                <button @click="nextStep" type="button" class="btn btn-primary">Suivant</button>
              </div>
            

            <div v-if="currentStep === 3 && niveau_academique !== 'non_universitaire'">
              <h2>Étape 3 : Détails sur votre projet</h2>
            <fieldset v-if="niveau_academique !== 'non_universitaire'">

            <div class="input-wrapper mb-3">
                  <label for="titre_solution" class="form-label">Intitulé du projet :</label>
                  <label class="required"></label>
                  <Field
                    name="titre_solution"
                    type="text"
                    label="titre_solution"
                    v-model="titre_solution"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="titre_solution"
                      v-bind="field"
                      placeholder="Entrer l'intitulé du projet"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="2"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="titre_solution"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="date_identification" class="form-label">Date de validation du projet :</label>
                  <Field
                    name="date_identification"
                    type="date"
                    v-model="date_identification"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      id="date_identification"
                      v-bind="field"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      type="date"
                    />
                  </Field>
                  <ErrorMessage name="date_identification" as="div" class="invalid-feedback" />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="description_solution" class="form-label">Description du projet :</label>
                  <label class="required"></label>
                  <Field
                    name="description_solution"
                    type="text"
                    label="description_solution"
                    v-model="description_solution"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="description_solution"
                      v-bind="field"
                      placeholder="Décrire la solution"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="4"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="description_solution"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="probleme_defi" class="form-label">Description du défi à relevé :</label>
                  <label class="required"></label>
                  <Field
                    name="probleme_defi"
                    type="text"
                    label="probleme_defi"
                    v-model="probleme_defi"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="probleme_defi"
                      v-bind="field"
                      placeholder="Décrire le(s) défi(s) traité(s)"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="3"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="probleme_defi"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="beneficiaires" class="form-label">Principaux bénéficiaires :</label>
                  <label class="required"></label>
                  <Field
                    name="beneficiaires"
                    type="text"
                    label="beneficiaires"
                    v-model="beneficiaires"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="beneficiaires"
                      v-bind="field"
                      placeholder="Lister les bénéficiaires"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="3"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="beneficiaires"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="form-check">
                  <Field
                    name="ameliorations_apportees"
                    type="radio"
                    value="oui"
                    v-model="ameliorations_apportees"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="ameliorations_oui"
                      v-bind="field"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                    <label class="form-check-label" for="ameliorations_oui">
                      Oui, des améliorations ont été apportées
                    </label>
                  </Field>
                </div>
                <div class="form-check">
                  <Field
                    name="ameliorations_apportees"
                    type="radio"
                    value="non"
                    v-model="ameliorations_apportees"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="ameliorations_non"
                      v-bind="field"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                    <label class="form-check-label" for="ameliorations_non">
                      Non, aucune amélioration n'a été apportée
                    </label>
                  </Field>
                </div>
                <div class="input-wrapper mb-3" v-if="ameliorations_apportees === 'oui'">
                  <label for="details_ameliorations" class="form-label">Détails sur les améliorations apportées :</label>
                  <Field
                    name="details_ameliorations"
                    type="text"
                    label="details_ameliorations"
                    v-model="details_ameliorations"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="details_ameliorations"
                      v-bind="field"
                      placeholder="Expliquer les améliorations"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="3"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="details_ameliorations"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>
                <div class="input-wrapper mb-3" v-if="ameliorations_apportees === 'oui'">
                  <label for="acteurs_ameliorations" class="form-label">Acteurs/Partenaires impliqués dans ces travaux d'amélioration :</label>
                  <Field
                    name="acteurs_ameliorations"
                    type="text"
                    label="acteurs_ameliorations"
                    v-model="acteurs_ameliorations"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="acteurs_ameliorations"
                      v-bind="field"
                      placeholder="Lister les acteurs/partenaires"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="3"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="acteurs_ameliorations"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>
                <ErrorMessage
                  name="ameliorations_apportees"
                  as="div"
                  class="invalid-feedback"
                />

                <div class="form-check">
                  <Field
                    name="desengagement"
                    type="radio"
                    value="confirmer"
                    v-model="desengagement"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="desengagement_confirmer"
                      v-bind="field"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                    <label class="form-check-label" for="desengagement_confirmer">
                      Je confirme mon désengagement
                    </label>
                  </Field>
                </div>
                <div class="form-check">
                  <Field
                    name="desengagement"
                    type="radio"
                    value="ne_pas_confirmer"
                    v-model="desengagement"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="desengagement_ne_pas_confirmer"
                      v-bind="field"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                    <label class="form-check-label" for="desengagement_ne_pas_confirmer">
                      Je ne confirme pas mon désengagement
                    </label>
                  </Field>
                </div>
                <ErrorMessage
                  name="desengagement"
                  as="div"
                  class="invalid-feedback"
                />

                <div class="input-wrapper mb-3">
                  <label for="valeur_ajoutee" class="form-label">Valeur Ajoutée du projet :</label>
                  <label class="required"></label>
                  <Field
                    name="valeur_ajoutee"
                    type="text"
                    label="valeur_ajoutee"
                    v-model="valeur_ajoutee"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="valeur_ajoutee"
                      v-bind="field"
                      placeholder="Décrire la valeur ajoutée"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="3"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="valeur_ajoutee"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>

                <div class="input-wrapper mb-3">
                  <label for="durabilite" class="form-label">Quels sont les ODD en lien avec votre projet :</label>
                  <label class="required"></label>
                  <Field
                    name="durabilite"
                    type="text"
                    label="durabilite"
                    v-model="durabilite"
                    v-slot="{ field, errorMessage }"
                  >
                    <textarea
                      id="durabilite"
                      v-bind="field"
                      placeholder="Expliquer la durabilité"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      rows="3"
                    ></textarea>
                  </Field>
                  <ErrorMessage
                    name="durabilite"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>
            </fieldset>
            <button @click="previousStep" class="btn btn-secondary">Précédent</button>
            <button @click="nextStep" type="button" class="btn btn-primary">Suivant</button>
          </div>
          <div v-if="currentStep === 4 && niveau_academique !== 'non_universitaire'">
            <h2>Étape 4 : Joindre Fichier</h2>
            <fieldset v-if="niveau_academique !== 'non_universitaire'">
                <div class="input-wrapper mb-3">
                  <label for="images" class="form-label">Illustration du projet :</label>
                  <label class="required"></label>
                  <Field
                    name="images"
                    type="file"
                    label="images"
                    v-model="images"
                    v-slot="{ field, errorMessage }"
                    multiple
                  >
                    <input
                      id="images"
                      v-bind="field"
                      type="file"
                      @change="handleFileUpload"
                      class="form-control"
                      :class="{ 'is-invalid': errorMessage }"
                      accept="image/*,.pdf"
                      multiple
                    />
                  </Field>
                  <ErrorMessage name="images" as="div" class="invalid-feedback" />
                </div>

                <div class="form-check">
                  <Field
                    name="autorisation_finale"
                    type="checkbox"
                    value="true"
                    v-model="autorisation_finale"
                    v-slot="{ field, errorMessage }"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="autorisation_finale"
                      v-bind="field"
                      :class="{ 'is-invalid': errorMessage }"
                    />
                    <label class="form-check-label" for="autorisation_finale">
                      J'ai lu et compris tous les choix donnés dans le formulaire.
                      J'assure l'UniPod, ses partenaires et le Gouvernement que
                      toutes les informations fournies sont correctes au meilleur de
                      mes connaissances.
                    </label>
                  </Field>
                  <ErrorMessage
                    name="autorisation_finale"
                    as="div"
                    class="invalid-feedback"
                  />
                </div>
              </fieldset>
              <button @click="previousStep" class="btn btn-secondary">Précédent</button>
              <button type="submit" class="btn btn-primary">Soumettre</button>
            </div>
            <div v-if="niveau_academique === 'non_universitaire'">
              <h2>Informations pour les non-universitaires</h2>
              <!-- New Section for Non-Universitaires -->
              <fieldset v-if="niveau_academique === 'non_universitaire'">
                <!-- <legend>Non-Universitaires :</legend> -->
                <div class="mb-3">
                  <label for="nomComplet" class="form-label">Nom complet :</label>
                  <label class="required"></label>
                  <Field id="nomComplet" name="nom_complet" placeholder="Entrer les noms et prénoms" class="form-control" />
                  <ErrorMessage name="nom_complet" class="error" />
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">Adresse email :</label>
                  <label class="required"></label>
                  <Field id="email" name="adresse_email" class="form-control" type="email" />
                  <ErrorMessage name="adresse_email" class="error" />
                </div>
                <div class="mb-3">
                  <label for="telephone" class="form-label">Numéro de téléphone :</label>
                  <label class="required"></label>
                  <Field id="telephone" name="numero_telephone" class="form-control" />
                  <ErrorMessage name="numero_telephone" class="error" />
                </div>
                <div class="mb-3">
                  <label for="nomEntreprise" class="form-label">Nom de l'entreprise/organisation :</label>
                  <Field id="nomEntreprise" name="nom_entreprise" class="form-control" />
                  <ErrorMessage name="nom_entreprise" class="error" />
                </div>
                <div class="mb-3">
                  <label for="poste" class="form-label">Poste occupé dans l'entreprise/organisation :</label>
                  <Field id="poste" name="poste_occupe" class="form-control" />
                  <ErrorMessage name="poste_occupe" class="error" />
                </div>
                <div class="mb-3">
                  <label for="descriptionProjet" class="form-label">Description du projet :</label>
                  <Field id="descriptionProjet" name="description_projet" class="form-control" />
                  <ErrorMessage name="description_projet" class="error" />
                </div>
                <div class="mb-3">
                  <label for="objectifsProjet" class="form-label">Objectifs du projet :</label>
                  <Field id="objectifsProjet" name="objectifs_projet" class="form-control" />
                  <ErrorMessage name="objectifs_projet" class="error" />
                </div>
                <div class="mb-3">
                  <label for="budget" class="form-label">Budget estimé :</label>
                  <Field id="budget" name="budget_estime" placeholder="Ex: 250000" class="form-control" />
                  <ErrorMessage name="budget_estime" class="error" />
                </div>
                <div class="mb-3">
                  <label for="dateDebut" class="form-label">Date de début souhaitée :</label>
                  <Field id="dateDebut" name="date_debut" class="form-control" type="date" />
                  <ErrorMessage name="date_debut" class="error" />
                </div>
                <div class="mb-3">
                  <label for="commentaires" class="form-label">Autres commentaires ou spécifications :</label>
                  <Field id="commentaires" name="autres_commentaires" class="form-control" />
                  <ErrorMessage name="autres_commentaires" class="error" />
                </div>
              </fieldset>
              <!-- End of New Section -->

              <button type="submit" class="btn btn-primary">Soumettre</button>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form as VeeForm, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";

configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

export default {
  inject: [
    "ToastSuccess",
    "ToastError",
    "AlertWarning",
    "AlertSuccess",
    "AlertError",
  ],
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  mounted() {
    Flatpickr("#date_identification", {
      dateFormat: 'Y-m-d', // Format de la date
      locale: 'fr', // Localisation
    });
    Flatpickr("#annee_soutenance", {
      dateFormat: 'Y', // Format de la date
      mode: 'single', // Mode de sélection unique
      enableTime: false, // Désactiver la sélection de l'heure
      locale: 'fr', // Localisation
    });
  },
  data() {
    return {
      currentStep: 1,
      years: [],
      domaines_application: [],
      domainOptions: [
        'Agriculture',
        'Energie',
        'Industrie',
        'Audiovisuel',
        'Mecanique',
        'Environnement',
        'Tic',
        'Electro-Technique',
        'Santé',
        'Santé Animal',
        'Autres'
      ],
      // Fields for academic and non-academic users
      niveau_academique: null,
      annee_soutenance: null,
      noms_candidats: null,
      email: null,
      telephone: null,
      autre_coordonnee: null,
      directeur_encadreur: null,
      etablissement_entite: null,
      titre_memoire: null,
      autorisation_collecte: null,
      reserve_collecte: null,
      titre_solution: null,
      date_identification: null,
      description_solution: null,
      // domaines_application: null,
      probleme_defi: null,
      beneficiaires: null,
      ameliorations_apportees: null,
      details_ameliorations: null,
      acteurs_ameliorations: null,
      desengagement: null,
      valeur_ajoutee: null,
      durabilite: null,
      images: null,
      autorisation_finale: false,

      // Fields for non-universitaires
      nom_complet: null,
      adresse_email: null,
      numero_telephone: null,
      nom_entreprise: null,
      poste_occupe: null,
      description_projet: null,
      objectifs_projet: null,
      budget_estime: null,
      date_debut: null,
      autres_commentaires: null,
    };
  },
  created() {
    // Générer la liste des années de 1980 à 2035
    for (let year = 1980; year <= 2035; year++) {
      this.years.push(year.toString())

    }
  },
  computed: {
    schema() {
    if (this.currentStep === 1) {
      return Yup.object().shape({
        niveau_academique: Yup.string().required("Le niveau académique est obligatoire"),
      });
    } else if (this.currentStep === 2 && this.niveau_academique !== 'non_universitaire') {
      return Yup.object().shape({
        annee_soutenance: Yup.number().typeError("L'année doit être un nombre").required("L'année de soutenance est obligatoire"),
        noms_candidats: Yup.string().required("Les noms et prénoms des candidats sont obligatoires"),
        email: Yup.string().email("L'email doit être valide").required("L'email est obligatoire"),
        telephone: Yup.string().nullable(),
        autre_coordonnee: Yup.string().nullable(),
        directeur_encadreur: Yup.string().required("Le nom de l'encadreur est obligatoire"),
        etablissement_entite: Yup.string().required("L'établissement est obligatoire"),
        titre_memoire: Yup.string().required("Le libellé du mémoire, thèse ou support de libération est obligatoire"),
        autorisation_collecte: Yup.string().required("Veuillez indiquer si vous autorisez la collecte d'informations"),
        reserve_collecte: Yup.string().when('autorisation_collecte', {
          is: val => val === 'non',
          then: schema => schema.required("Veuillez préciser vos réserves concernant la collecte d'informations"),
        }),
      });
    } else if (this.currentStep === 3 && this.niveau_academique !== 'non_universitaire') {
      return Yup.object().shape({
        titre_solution: Yup.string().required("L'intitulé du projet est obligatoire"),
        date_identification: Yup.date().required("La date de validation est obligatoire"),
        description_solution: Yup.string().required("La description du projet est obligatoire"),
        domaines_application: Yup.array()
          .of(Yup.string())
          .min(1, "Au moins un domaine d'application doit être sélectionné")
          .required("Les domaines d'application sont obligatoires"),
        probleme_defi: Yup.string().required("Le défi à résoudre est obligatoire"),
        beneficiaires: Yup.string().required("Les bénéficiaires sont obligatoires"),
        ameliorations_apportees: Yup.string().required("Les améliorations apportées sont obligatoires"),
        details_ameliorations: Yup.string().when('ameliorations_apportees', {
          is: val => val === 'oui',
          then: schema => schema.required("Veuillez fournir des détails sur les améliorations apportées"),
        }),
        acteurs_ameliorations: Yup.string().when('ameliorations_apportees', {
          is: val => val === 'oui',
          then: schema => schema.required("Veuillez préciser les acteurs impliqués dans les améliorations"),
        }),
        desengagement: Yup.string().required("Le désengagement est obligatoire"),
        valeur_ajoutee: Yup.string().required("La valeur ajoutée est obligatoire"),
        durabilite: Yup.string().required("La durabilité est obligatoire"),
      });
    } else if (this.currentStep === 4 && this.niveau_academique !== 'non_universitaire') {
      return Yup.object().shape({
        images: Yup.mixed().nullable(),
        autorisation_finale: Yup.boolean().oneOf([true], "Vous devez donner votre autorisation finale"),
      });
    } else if (this.niveau_academique === 'non_universitaire') {
      return Yup.object().shape({
        nom_complet: Yup.string().required("Nom complet est requis"),
        adresse_email: Yup.string().email("Email invalide").required("Adresse email est requise"),
        numero_telephone: Yup.string().required("Numéro de téléphone est requis"),
        nom_entreprise: Yup.string().required("Nom de l'entreprise est requis"),
        poste_occupe: Yup.string().required("Poste occupé est requis"),
        description_projet: Yup.string().required("Description du projet est requise"),
        objectifs_projet: Yup.string().required("Objectifs du projet sont requis"),
        budget_estime: Yup.number().required("Budget estimé est requis"),
        date_debut: Yup.date().required("Date de début souhaitée est requise"),
        autres_commentaires: Yup.string().nullable(),
        autorisation_finale: Yup.boolean().oneOf([true], "Vous devez donner votre autorisation finale"),
      });
    }
    return Yup.object().shape({});
  },
  },
  methods: {
    handleFileUpload(event) {
      this.images = event.target.files;
    },
    async validateStep() {
      try {
        const currentStepSchema = this.getCurrentStepSchema();
        const currentStepData = this.getCurrentStepData();
        await currentStepSchema.validate(currentStepData, { abortEarly: false });
        return true;
      } catch (error) {
        console.error("Validation failed", error.errors);
        this.ToastError.fire({
          title: "Erreur de validation",
          text: error.errors.join(', ')
        });
        return false;
      }
    },

    getCurrentStepSchema() {
      // Retourne le schéma de validation pour l'étape actuelle
      return this.schema;
    },

    getCurrentStepData() {
      // Retourne les données pour l'étape actuelle
      switch(this.currentStep) {
        case 1:
          return { niveau_academique: this.niveau_academique };
        case 2:
          return {
            annee_soutenance: this.annee_soutenance,
            noms_candidats: this.noms_candidats,
            email: this.email,
            telephone: this.telephone,
            autre_coordonnee: this.autre_coordonnee,
            directeur_encadreur: this.directeur_encadreur,
            etablissement_entite: this.etablissement_entite,
            titre_memoire: this.titre_memoire,
            autorisation_collecte: this.autorisation_collecte,
            reserve_collecte: this.autorisation_collecte === 'oui' ? 'non' : this.reserve_collecte
          };
          case 3:
            return {
              titre_solution: this.titre_memoire,
              date_identification: this.date_identification,
              description_solution: this.description_solution,
              domaines_application: Array.isArray(this.domaines_application) 
              ? this.domaines_application 
              : (typeof this.domaines_application === 'string' ? JSON.parse(this.domaines_application || '[]') : []),
              // domaines_application: this.domaines_application,
              probleme_defi: this.probleme_defi,
              beneficiaires: this.beneficiaires,
              ameliorations_apportees: this.ameliorations_apportees,
              details_ameliorations: this.ameliorations_apportees === 'non' ? 'non' : this.details_ameliorations,
              acteurs_ameliorations: this.ameliorations_apportees === 'non' ? 'non' : this.acteurs_ameliorations,
              desengagement: this.desengagement,
              valeur_ajoutee: this.valeur_ajoutee,
              durabilite: this.durabilite,
            };
          case 4:
            return {
              images: this.images,
              autorisation_finale: this.autorisation_finale,
            };
          default:
            return{
              nom_complet: this.nom_complet,
              adresse_email: this.adresse_email,
              numero_telephone: this.numero_telephone,
              nom_entreprise: this.nom_entreprise,
              poste_occupe: this.poste_occupe,
              description_projet: this.description_projet,
              objectifs_projet: this.objectifs_projet,
              budget_estime: this.budget_estime,
              date_debut: this.date_debut,
              autres_commentaires: this.autres_commentaires,
            }
      }
    },
  async nextStep() {
  if (this.currentStep < 4 && this.niveau_academique !== 'non_universitaire') {
    console.log("Données actuelles:", this.getCurrentStepData());
    const isValid = await this.validateStep();
    console.log("Validation réussie:", isValid);
    if (isValid) {
      this.currentStep++;
      console.log("Passage à l'étape:", this.currentStep);
    } else {
      console.log("La validation a échoué. Restez à l'étape:", this.currentStep);
      this.ToastError.fire({
        title: "Veuillez remplir correctement tous les champs obligatoires avant de continuer.",
      });
    }
  }
},
  
  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  },
  async submitForm() {
  for (let step = 1; step <= 4; step++) {
    this.currentStep = step;
    const isValid = await this.validateStep();
    if (!isValid) {
      this.ToastError.fire({
        title: `Veuillez corriger les erreurs à l'étape ${step} avant de soumettre.`,
      });
      return;
    }
  }
  this.onSubmit();
},
  onSubmit() {
  const formData = this.collectAllData();
  this.sendDataToServer(formData);
},

collectAllData() {
  const formData = new FormData();

  // Étape 1
  formData.append("niveau_academique", this.niveau_academique);

  if (this.niveau_academique !== 'non_universitaire') {
    // Étape 2
    const step2Fields = ["annee_soutenance", "noms_candidats", "email", "telephone", "autre_coordonnee",
      "directeur_encadreur", "etablissement_entite", "titre_memoire", "autorisation_collecte", "reserve_collecte"];
    step2Fields.forEach(field => {
      formData.append(field, this[field] || "");
    });

    // Étape 3
    const step3Fields = ["titre_solution", "date_identification", "description_solution",
      "probleme_defi", "beneficiaires", "ameliorations_apportees", "details_ameliorations", "acteurs_ameliorations",
      "desengagement", "valeur_ajoutee", "durabilite"];

    step3Fields.forEach(field => {
      formData.append(field, this[field] || "");
    });

    // Traitement spécial pour domaines_application
    if (Array.isArray(this.domaines_application)) {
      this.domaines_application.forEach((domain, index) => {
        formData.append(`domaines_application[${index}]`, domain);
      });
    } else if (typeof this.domaines_application === 'string') {
      const domains = JSON.parse(this.domaines_application || '[]');
      domains.forEach((domain, index) => {
        formData.append(`domaines_application[${index}]`, domain);
      });
    } else {
      formData.append('domaines_application', JSON.stringify([]));
    }
    // Étape 4
    formData.append("autorisation_finale", this.autorisation_finale);
    if (this.images) {
      for (let i = 0; i < this.images.length; i++) {
        formData.append("images[]", this.images[i]);
      }
    }
  } else {
    // Champs pour les non-universitaires
    const nonAcademicFields = ["nom_complet", "adresse_email", "numero_telephone", "nom_entreprise",
      "poste_occupe", "description_projet", "objectifs_projet", "budget_estime", "date_debut", "autres_commentaires"];
    nonAcademicFields.forEach(field => {
      formData.append(field, this[field] || "");
    });
  }

  return formData;
},

sendDataToServer(formData) {
  this.$http.post("soumettre-projet", formData)
    .then(() => {
      this.AlertSuccess.fire({
        text: "Votre projet a été soumis avec succès !",
      });
      this.$router.push({ name: "Status" });
    })
    .catch((error) => {
          if (error.response.status == 422) {
            this.ToastError.fire({
              title: "Veuillez bien remplir le formulaire et réessayez.",
            });

            let errors = error.response.data.errors;
            for (const key in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, key)) {
                const element = errors[key];
                this.$refs.form.setFieldError(key, element);
              }
            }
          } else if (error.response.status == 403) {
            this.AlertError.fire({
              icon: "error",
              title: "Non autorisé",
              html: "Vous ne pouvez plus modifier votre candidature.",
            });
          } else {
            this.ToastError.fire({
              title: "Une erreur s'est produite, veuillez réessayer.",
            });
          }
    });
},
},

};
</script>


<style scoped>
.font {
  font-family: 'proxima-nova', sans-serif;
  /* text-align: justify; */
  font-size: 16.5px;
}

.bg-header {
  position: relative;
  background: url('../assets/img/idee1.jpg') center/cover no-repeat;
  padding: 60px 0;
}

.bg-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd96f;
  pointer-events: none;
}

.btn{
  margin-top: 30px;
}

.form-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* fieldset legend {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
} */

.form-label {
  font-weight: 600;
  margin-top: 5px;
}

.btn-primary {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.error {
color: rgba(255, 0, 0, 0.9);
}

@media (min-width: 992px) {
  .bg-header {
    position: relative;
    background: url('../assets/img/idee1.jpg') center/cover no-repeat;
    height: 500px;
  }
}

.step-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.step-progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.step-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #808285;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.step-indicator.active {
  background-color: #1D9ED9;
}

.step-indicator.completed {
  background-color: #F4D218;
}

.step-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.step-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-primary {
  background-color: #1D9ED9;
  border-color: #1D9ED9;
}

.btn-secondary {
  background-color: #F4D218;
  border-color: #808285;
}
</style>