<template>
  <div class="form-check">
    <input
      :name="name"
      :id="name"
      type="checkbox"
      :value="value"
      @input="handleChange"
      @blur="handleBlur"
      class="form-check-input"
      :class="{ 'is-invalid': !!errorMessage }"
    />
    <label class="form-check-label" :for="name">
      J'ai lu et accepté
      <router-link to="/cgu">les conditions d'utilisation</router-link>
    </label>
    <div class="invalid-feedback">
      Vous devez accepter les conditions d'utilisation pour vous inscrire.
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "CguCheckbox",
  props: {
    value: {
      type: String,
      default: "false",
    },
    name: {
      type: String,
      default: "checkboxCGU",
    },
  },
  // eslint-disable-next-line vue/no-setup-props-destructure
  setup({ name, value }) {
    const { checked, handleChange, errorMessage, handleBlur } = useField(
      name,
      undefined,
      {
        type: "checkbox",
        checkedValue: value,
      }
    );

    return {
      checked,
      handleChange,
      handleBlur,
      errorMessage,
    };
  },
};
</script>

<style  scoped>
.form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  margin-right: 10px;
}

label {
  display: block !important;
}
</style>