<template>
  <div class="col-lg-4 d-flex justify-content-center wow slideInUp" data-wow-delay="0.1s">
    <div class="blog-item rounded overflow-hidden border custom-card">
      <div class="blog-img position-relative overflow-hidden text-center">
        <router-link style="text-decoration: none;" :to="`/blog/${id}/${slug}`">
          <img class="img-fluid" :src="'https://admin.benin-unipod.bj/api/candidate/storage/' + image" :alt="title">
          <!-- <img class="img-fluid" :src="require(`../../../eeia-api/storage/app/public/${image}`)" :alt="title"> -->
        </router-link>
        <!-- <div class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4 font-weight-bold">{{ category }}</div> -->
      </div>
      <div class="p-4">
        <router-link style="text-decoration: none; color: inherit;" :to="`/blog/${id}/${slug}`">
          <h5 class="mb-3 font-weight-bold">{{ truncateText(title, extract, 220).title }}</h5>
        </router-link>
        <p>{{ truncateText(title, extract, 220).extract }}</p>
        <router-link class="text-uppercase" style="text-decoration: none;" :to="`/blog/${id}/${slug}`">Lire plus<i
            class="fa fa-chevron-right"></i></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    slug: String,
    image: String,
    category: String,
    author: String,
    date: String,
    title: String,
    content: String,
    extract: String
  },

  methods: {
    truncateText(title, extract, maxLength) {
      const combinedLength = title.length + extract.length;
      if (combinedLength > maxLength) {
        return { title, extract: extract.slice(0, maxLength - title.length) + '...' };
      } else {
        return { title, extract };
      }
    }
  },
}
</script>

<style scoped>
.blog-item {
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: #eef9ffe8;
  border-radius: 15px;
}

.blog-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(29, 158, 217, 0.2);
}

.blog-img {
  position: relative;
  overflow: hidden;
}

.blog-img img {
  transition: all 0.5s ease;
}

.blog-item:hover .blog-img img {
  transform: scale(1.1);
}

.blog-item .position-absolute {
  transition: all 0.3s ease;
}

.blog-item:hover .position-absolute {
  padding-right: 30px;
}

.text-uppercase {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease;
}

.text-uppercase::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #1d9ed9;
  transition: all 0.3s ease;
}

.blog-item:hover .text-uppercase::after {
  width: 100%;
}

.text-uppercase i {
  transition: transform 0.3s ease;
}

.blog-item:hover .text-uppercase i {
  transform: translateX(8px);
}

.custom-card {
  border-color: #1d9ed9 !important;
  height: 100%;
}

.font-weight-bold {
  font-weight: bold;
}

@media (min-width: 768px) {
  .custom-card {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(29, 158, 217, 0.1);
  }

  .blog-img {
    width: 100%;
    height: 200px;
  }

  .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .custom-card {
    height: 500px;
  }

  .blog-img {
    height: 230px;
  }
}

@media (max-width: 767px) {
  .blog-item {
    margin-bottom: 30px;
  }
}
</style>