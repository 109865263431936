<template>
  <!-- <the-lightrope></the-lightrope> -->
  <div :class="{ 'register-bg': isRegisterPage }">
  <!--navbar-->
  <router-view name="navbar" :updateNav="navKey"></router-view>
  <Transition appear>
  <!--main-->
    <main class="main">
      <router-view name="main" v-slot="{ Component, route }">
        <!-- Use any custom transition and fallback to `fade` -->
        <transition :name="route.meta.transition || 'fade'">
          <component :is="Component" 
          v-on:update-nav="navKey++"/>
        </transition>
      </router-view>
    </main>
  </Transition>

  <!--footer-->
  <router-view name="footer"></router-view>
  <the-loader></the-loader>
  <!-- <the-lightrope style="transform: rotateX(180deg);"></the-lightrope> -->
</div>
</template>

<script>
import TheLoader from "./components/TheLoader.vue";
// import TheLightrope from "./components/TheLightrope.vue";

export default {
  name: "App",
  components: {
    TheLoader,
    // TheLightrope,
  },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#f8f9fa";
  },
  created() {
  },
  data() {
    return {
      currentStep: 0,
      navKey: 0,
    };
  },
  methods: {
  },
  computed: {
    isRegisterPage() {
      return this.$route.name === 'Register';
    }
  },
};
</script>

<style scoped>

.main {
  /* min-height: 50vh; */
}
.register-bg {
  background-image: url('static/filigrane.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/**Slide */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-out;
}


.slide-enter-to {
  position: absolute;
  right: 0;
}


.slide-enter-from {
  position: absolute;
  right: -100%;
}


.slide-leave-to {
  position: absolute;
  left: -100%;
}


.slide-leave-from {
  position: absolute;
  left: 0;
}
/**scale */
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease, transform 1s ease-in-out;
  transform: translateY(0px);
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
</style>
