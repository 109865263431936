<template>
    <div>
        <Partners></Partners>
        <!-- Newsletter Start -->        
        <div class="container-fluid bg-primary newsletter py-5">
            <div class="container">
                <div class="row g-5 align-items-center">
                    <div class="col-md-5 ps-lg-0 pt-5 pt-md-0 text-star" data-aos="fade-in">
                        <img class="img-fluid" src="../assets/img/newsletter.png" alt="">
                    </div>
                    <div class="col-md-7 py-5 newsletter-text" data-aos="fade-in">
                        <div class=" btn-sm border rounded-pill txt px-3 mb-3 py-1" style="width: 155px;">
                            <font-awesome-icon :icon="['fas', 'envelope']" style="color: #ebc506;"/> Newsletter
                        </div>
                        <h3 class="text-white mb-4">Restez informé des évènements de l'UniPod Bénin</h3>
                        <div class="position-relative w-100 mt-3 mb-2">
                            <input class="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text"
                                placeholder="Entrez votre Email" style="height: 48px;">
                            <button type="button" class="btn shadow-none position-absolute top-0 end-0 mt-1 me-2">
                                <i class="fa fa-paper-plane text-primary fs-4"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Newsletter End -->

        <!-- Footer Start -->
        <div class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-4 footer-about">
                        <router-link to="/">
                            <img class="img align-middle logo-unipod"
                                src="../static/Unipod-logo.png"
                                alt="Unipod"
                                width="200"
                                height="75"
                            />
                        </router-link>
                        <button @click="toggleText" class="btn btn-primary mt-3 btn-en-savoir-plus">
                            En savoir plus <font-awesome-icon :icon="['fas', 'angle-right']" style="margin-left: 10px;" />
                        </button>

                        <p v-if="showText" class="mt-3 text-justify">
                            Est à la fois un espace physique et une culture créative dans lesquels les innovateurs potentiels peuvent explorer leur talent inné de concepteur pour le développement de solutions, indépendamment de leur parcours académique.
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-8">
                        <div class="row">
                            <div class="col-md-6 col-lg-4">
                                <div class="footer-link">
                                    <h3>Liens utiles</h3>
                                    <a href="/">Accueil</a>
                                    <a href="/contact">Nous contacter </a>
                                    <a href="/faq">FAQ</a>
                                    <a href="/about">A propos</a>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="footer-link">
                                    <h3>Nous rejoindre</h3>
                                    <a href="/register">S'inscrire</a>
                                    <a href="/login">Se connecter</a>
                                    <a href="/cgu">Conditions d'utilisations</a>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="footer-contact">
                                    <h3>Nos adresses</h3>
                                    <p>
                                        <a class="map-link" href="https://www.google.com/maps/place/UNIPOD+B%C3%A9nin/@6.4192786,2.3386599,17z/data=!3m1!4b1!4m6!3m5!1s0x1024a981b4d388d9:0x6e4d879d6acc9c1f!8m2!3d6.4192786!4d2.3386599!16s%2Fg%2F11ldhg0r2v?entry=ttu" target="_blank">
                                            <font-awesome-icon :icon="['fas', 'map-marker-alt']" style="color: #1d9ed9;" /> Université d'Abomey-Calavi, République du Benin
                                        </a>
                                    </p>
                                    <p>
                                        <a class="map-link" href="mailto:info@benin-unipod.bj">
                                            <font-awesome-icon :icon="['fas', 'envelope']" style="color: #1d9ed9;"/> info@benin-unipod.bj
                                        </a>
                                    </p>
                                    <div class="footer-social">
                                        <a href=""><i class="fab fa-twitter" style="color: #1d9ed9;"></i></a>
                                        <a href=""><i class="fab fa-facebook-f" style="color: #1d9ed9;"></i></a>
                                        <a href=""><i class="fab fa-youtube" style="color: #1d9ed9;"></i></a>
                                        <a href=""><i class="fab fa-linkedin-in" style="color: #1d9ed9;"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="container copyright">
                <div class="row">
                    <div class="col-md-12">
                        <p>&copy; 2024 UniPod Bénin. Tous droits reservés.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer End -->
    </div>
</template>
<!-- @import '../assets/styles/footer-1.css'; -->
<style scoped lang="css">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import '../assets/styles/footer-1.css';


</style>

<script>
import AOS from 'aos';
import Partners from "../components/Partners.vue";   
export default{
    components: {
        Partners
    },
    data() {
        return {
            showText: false
        };
    },
    mounted(){
        AOS.init();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            AOS.refresh();
        },
        toggleText() {
            this.showText = !this.showText;
        }
    }
}
</script>