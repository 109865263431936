<template>
  <section id="home">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <Transition appear>
            <section id="hero" class="d-flex">
              <div id="header-carousel" class="carousel slide carousel-fade top w-100" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div v-if="carouselsData">
                    <div v-for="(carousel, carindex) in carouselsData" :key="carindex"
                      :class="['carousel-item', carindex === 0 ? 'active' : '']">
                      <img :src="'https://admin.benin-unipod.bj/api/candidate/storage/' + carousel.image"
                        class="d-block w-100 img-carousel" alt="Image UNIPOD BENIN" />
                      <!-- <img :src="require(`../../../eeia-api/storage/app/public/${image}`)" class="d-block w-100 img-carousel" alt="Image UNIPOD BENIN" /> -->
                      <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <transition name="fade">
                          <div class="p-3 carousel-content" style="max-width: 900px;">
                            <h2 class="text-uppercase slide-in-down">{{ carousel.heading }}</h2>
                            <p class="display-1 text-white mb-md-4 zoom-in">{{ carousel.subheading }}</p>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                  <div v-else class="carousel-item active">
                    <!-- Image et textes par défaut -->
                    <img src="../static/hb.png" class="d-block w-100 img-carousel" alt="UNIPOD BENIN" />
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                      <div class="p-3 carousel-content" style="max-width: 900px;">
                        <h2 class="text-uppercase">Tout le monde peut être un créateur ou un innovateur</h2>
                        <p class="display-1 text-white mb-md-4">La conception et le prototypage de produits innovants ne
                          sont pas que destinés aux ingénieurs</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </section>
          </Transition>
          <section style="margin-top:50px; margin-bottom: 20px;">
            <SectionTwo></SectionTwo>
          </section>


          <!-- <section id="intro" class="py-16">
            <div class="container">
              <div class="max-auto mx-auto text-center" style="max-width: 600px;">
                <h2 class="text-h4 text-md-h3 text-center font-weight-black mb-7">
                  Transformer les universités publiques d'Afrique en des espaces
                  d'<strong style="color: #f4d218">innovation</strong>, d'
                  <strong style="color: #1d9ed9">expérimentation</strong> et de
                  <strong style="color: #808285">transformation accélérée</strong>.
                </h2>
                <img class="img-fluid" src="../static/h5.png" alt="h5.png" size="100%"/>
              </div>
            </div>
          </section> -->

          <section style="margin-top:50px; margin-bottom: 20px;">
            <div class="actuality">
              <h4 class="title-actuality">
                <span style="display: inline-block;">
                  <hr class="separator"
                    style="width: 60px; margin-right: 10px; margin-top: 5px; margin-bottom: 5px; border-width: 2px;">
                </span>
                Formations
              </h4>
              <!-- <div style="font-size:20px;">
                <p class="font-weight-bold title-news">À la une</p>
              </div> -->
            </div>
            <CardTraining></CardTraining>

          </section>

          <section style="margin-top:50px; margin-bottom: 20px;">
            <div class="actuality">
              <h4 class="title-actuality">
                <span style="display: inline-block;">
                  <hr class="separator"
                    style="width: 60px; margin-right: 10px; margin-top: 5px; margin-bottom: 5px; border-width: 2px;">
                </span>
                Programme d'Accompagnement
              </h4>
              <!-- <div style="font-size:20px;">
                <p class="font-weight-bold title-news">À la une</p>
              </div> -->
            </div>
            <CardSupport></CardSupport>
          </section>

          <section style="margin-top:50px; margin-bottom: 20px;">
            <div class="actuality">
              <h4 class="title-actuality">
                <span style="display: inline-block;">
                  <hr class="separator"
                    style="width: 60px; margin-right: 10px; margin-top: 5px; margin-bottom: 5px; border-width: 2px;">
                </span>
                À la une
              </h4>
              <!-- <div style="font-size:20px;">
                <p class="font-weight-bold title-news">À la une</p>
              </div> -->
            </div>
            <CardNews></CardNews>

          </section>

          <!-- <section class="py-16 rounded mt-0" style="background-color: rgba(15, 23, 248, 0.257) ;">
            <div :class="{
              'grey darken-4 primary--text text--lighten-1': isDarkMode,
              'grey lighten-4 primary--text': !isDarkMode
            }" class="primary--text">
              <div class="container rounded">
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-7 text-center">
                    <h2 class="text-h4 text-md-h3 text-center font-weight-bold text-uppercase mb-4 font">
                      UniPod Bénin
                    </h2>
                    <p class="font">Pour tout renseignements, veuillez nous joindre ici</p>
                  </div>
                  <div class="col-12 col-sm-5 align-self-center text-center">
                    <button class="btn bg-primary btn-lg rounded"><a href="/contact"
                        style="text-decoration: none; color: #fff;">Nous joindre</a></button>
                  </div>
                </div>
              </div>
            </div>
          </section> -->

          <!-- <section id="timeline" class="py-5 programme">
            <div class="container">
              <h2 class="text-dark text-center fw-bold mb-4">
                Nos programmes
              </h2>
              <div class="timeline">
                <div v-for="(program, i) in programs" :key="i" class="timeline-item rounded" style="border: 1px solid #808285;"
                  data-aos="fade-up">
                  <div class="timeline-badge"></div>
                  <div class="timeline-content" :class="{ 'left': i % 2 === 0, 'right': i % 2 !== 0 }">
                    <h3 class="title-blue">{{ program.titre }}</h3>
                    <div class="content font">
                      {{ program.extract }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> -->
        </div>
      </div>
    </div>

  </section>
</template>
<script>
//import Partners from "../components/Partners.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import CardNews from "../components/CardNews.vue";
import CardSupport from "../components/CardSupport.vue";
import CardTraining from "../components/CardTraining.vue";
import SectionTwo from "../components/SectionTwo.vue";

export default {
  components: {
    //Partners
    CardNews,
    CardSupport,
    CardTraining,
    SectionTwo
  },
  data() {
    return {
      carouselsData: [],
      items: [
        { title: "Prototypage rapide (impression 3D et découpe laser)" },
        {
          title:
            "Réalisation Audio-visuelle et Réalité Augmentée/ Visuelle (RA/RV)",
        },
        { title: "Électricité et électronique" },
        { title: "Travail de bois, greentech" },
        { title: "Technologie agroalimentaire et textiles/fabriques" },
      ],
      programs: [],
      isDarkMode: false,
      News: [],
    };
  },
  computed: {
    isLargeScreen() {
      return window.innerWidth >= 992;
    },
    timelineItemsWithSide() {
      return this.timelineItems.map((year, i) => {
        i.side = i % 2 === 0 ? 'right' : 'left';
        return i;
      });
    },
  },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#ffffff";
    AOS.init();
    window.addEventListener('scroll', this.handleScroll);
    this.fetchCarouselsData();
    this.fetchPrograms();

  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      AOS.refresh();
    },
    handleResize() {
      this.$forceUpdate();
    },
    fetchCarouselsData() {
      this.$http.get('carousels')
        .then(response => {
          this.carouselsData = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    fetchPrograms() {
      this.$http.get('programs')
        .then(response => {
          this.programs = response.data.data;
          // console.log(this.programs);
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped lang="css">
@import '../assets/styles/carousel.css';
</style>
<style scoped>
.font {
  font-family: 'proxima-nova', sans-serif;
}

.conteneur {
  max-width: 100%;
}

#intro {
  background-color: #f8f9fa;
  padding-top: 4rem;
  /*padding-bottom: 4rem;*/
}

.top {
  margin-top: 95px;
}

.programme {
  background-color: #fafafa;
  margin-top: 50px;
}

.slide-in-down {
  opacity: 0;
  animation-name: slideInDown;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.zoom-in {
  opacity: 0;
  animation-name: zoomIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.zoom-in {
  opacity: 0;
  animation-name: zoomIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    /* Start with opacity 0 */
  }

  to {
    opacity: 1;
    /* Transition to opacity 1 */
  }
}

/*  */

.timeline {
  position: relative;
}

.timeline-badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #1d9ed9;
}

/*  */

.timeline-item {
  position: relative;
  margin-bottom: 4rem;
}

.timeline-content {
  position: relative;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
}

.timeline-content.right {
  margin-left: 0;
  /* margin-right: 40px; */
  text-align: justify;

}

.timeline-content.left {
  text-align: left;
}

.timeline-content.right h3 {
  text-align: right;
}

.timeline-content.left h3 {
  text-align: left;
}

.title-blue {
  color: #1d9ed9;
}

.title-yellow {
  color: #1d9ed9;
}

.timeline-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-content p {
  font-size: 15px;
  font-weight: bold;
}

.content {
  font-size: 1rem;
}


@media (min-width: 992px) {
  .actuality {
    margin-left: 200px;
  }

  .top {
    margin-top: 57px;
  }
}</style>
