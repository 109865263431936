<template>
  <div class="form-wrapper">
    <main class="form-signin shadow-lg">
      <div class="text-center px-4">
        <a class="title" href="/">
          <h1 class="h2 mt-4 mb-3 text-center">
              UniPod Bénin
            </h1>
        </a>
        <!-- <img src="../assets/img/Unipod-Logo-PNG.png" class="img-fluid" /> -->
      </div>

      <h3 class="h3 mb-4 mt-4 text-center">
        <u>Réinitialiser votre mot de passe</u>
      </h3>

      <p class="fs-3" style="text-align: justify">
        Mot de passe oublié ? Pas de soucis. Veuillez nous indiquer votre
        adresse email et nous vous enverrons un lien de réinitialisation du mot
        de passe.
      </p>

      <div class="container-fluid">
        <div class="row justify-content-center">
          <VeeForm
            class="col-sm-8 px-0"
            v-slot="{ handleSubmit }"
            :validation-schema="schema"
            as="div"
          >
            <div
              class="alert alert-danger d-none"
              id="alertError"
              role="alert"
            ></div>
            <form @submit="handleSubmit($event, onSubmit)">
              <div class="mb-3">
                <label for="emailInput" class="form-label">Email</label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Entrer votre email ici."
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="emailInput"
                    v-bind="field"
                    v-model="email"
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                    aria-describedby="helpBlock"
                  />
                </Field>
                <div id="helpBlock" class="form-text">
                  <u><b>NB</b></u
                  >: Le lien sera envoyé si l'email existe dans notre base de
                  données.
                </div>
                <ErrorMessage name="email" as="div" class="invalid-feedback" />
              </div>
              <button class="w-100 btn btn-lg btn-primary" type="submit">
                Envoyer le lien
              </button>
            </form>
          </VeeForm>
        </div>
      </div>

      <div class="text-center fs-6 mt-4">
        <img src="../assets/img/Unipod-Logo-PNG.png" height="80" alt="" />
        <p class="mb-1 text-muted">
          &copy; Unipod–<span id="currentYear">2023</span>
        </p>
        <div class="mb-3 text-muted">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a href="#" @click="reconnect()"
                >Retourner sur la page de connexion</a
              >
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, min } from "@vee-validate/rules";

defineRule("required", required);
defineRule("email", email);
defineRule("min", min);

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

export default {
  name: "PasswordForget",
  inject: ["AlertSuccess", "ToastError", "currentYearUrl"],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    msg: String,
  },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#1D9ED9";
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2023";
      });
  },
  data() {
    const schema = {
      email: "required|email",
    };
    return {
      schema,
      email: localStorage.getItem("email"),
    };
  },
  methods: {
    onSubmit(values) {
      this.$http({
        url: "forgot-password",
        method: "post",
        data: {
          email: values.email,
        },
      })
        .then(() => {
          this.AlertSuccess.fire({
            text: "Le lien a été envoyé. Rendez-vous dans votre boite mail pour finaliser le processus.",
          });
          localStorage.setItem("email", this.email);
        })
        .catch(() => {
          this.ToastError.fire({
            title: "Une erreur s'est produite, veuillez réessayer.",
          });
        });
    },
    reconnect() {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      this.$router.push("login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  /* background-image: var(--bs-gradient)!important; */
  background-color: #1D9ED9 !important;
  font-size: 20px;
}

.title {
    text-decoration: none;
  }

@media (min-width: 768px) {
  .form-control {
    line-height: 2.5;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1.5rem !important;
  font-weight: bold !important;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 11vh;
  margin-bottom: 8vh;
}

@media (max-width: 575.98px) {
  .form-wrapper {
    margin-top: 7.5vh !important;
    margin-bottom: 4vh !important;
  }

  .form-signin {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.form-signin {
  padding: 15px;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  background: white;
  border-radius: 8px;
}

ul.resend {
  padding-left: 3rem;
}

.img-wrapper {
  width: 130px;
  margin: auto;
}
</style>
